import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput as CommonInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputPassword as CommonInputPassword } from '@app/components/common/inputs/InputPassword/InputPassword';
import loginBackground from '@app/assets/images/login-bg.webp';
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Link } from 'react-router-dom';
import './setting.css';
export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`;

export const BackgroundWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: #b9c4ff;
  width: 100%;

  background-size: cover;
  position: relative;
`;
//background: url(${loginBackground});

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormWrapper = styled.div`
  font-family: 'NotoSansKR', sans-serif;

  padding: 2.5rem;
  width: 70vh;
  height: 100vh;
  overflow: auto;
  background-color: rgba(var(--background-rgb-color), 0.93);
  border-radius: ${BORDER_RADIUS};

  @media only screen and ${media.xs} {
    padding: 2.5rem 1.25rem;
    max-height: calc(100vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media only screen and ${media.md} {
    padding: 2.5rem;
    max-height: calc(100vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const FormTitle = styled.div`
  color: var(--primary-color);

  @media only screen and ${media.xs} {
    margin-bottom: 0.625rem;
    font-size: ${FONT_SIZE.lg};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.5625rem;
  }

  @media only screen and ${media.md} {
    margin-bottom: 0.875rem;
    font-size: ${FONT_SIZE.xxl};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.9375rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 0.9375rem;
    font-size: ${FONT_SIZE.xxxl};
    font-weight: ${FONT_WEIGHT.extraBold};
    line-height: 2.125rem;
  }
`;

export const FormCheckbox = styled(BaseCheckbox)`
  display: flex;
  padding-left: 0.125rem;

  & .ant-checkbox {
    width: 20px;
  }

  & .ant-checkbox-inner {
    width: 20px;
    border-radius: 3px;
    transform: scale(1.375);
  }

  & .ant-checkbox-input {
    width: 20px;
    transform: scale(1.375);
  }
`;

export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`;

export const FormInput = styled(CommonInput)`
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
`;

export const FormInputPassword = styled(CommonInputPassword)`
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Text = styled.span`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  padding-left: 0.5rem;
`;

export const LinkText = styled(Text)`
  text-decoration: underline;
  color: var(--primary-color);
`;

export const SubmitButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
`;

export const SocialButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

export const FooterWrapper = styled.div`
  margin-top: 1.25rem;
  text-align: center;
`;

export const BackIcon = styled(LeftOutlined)`
  font-size: 0.75rem;
  margin-right: 0.75rem;
`;

export const BackWrapper = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
`;

export const SocialIconWrapper = styled.div`
  display: flex;
  margin-right: 0.8125rem;
  @media only screen and ${media.xs} {
    margin-right: 0.625rem;
  }

  @media only screen and ${media.md} {
    margin-right: 0.8125rem;
  }
`;

export const SocialLoginContainer = styled.div`
  margin: 30px 0 0 10px;
`;
export const SocialLoginText = styled(Text)`
  display: block;

  width: 100%;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
  line-height: 0.1em;
  margin: 10px 0 20px;
  position: relative;
  z-index: 0;
  > span {
    background: #fff;
    padding: 0 10px;
  }
`;

export const SocialLoginIconBox = styled.ul`
  list-style-type: none;
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const Balloon = styled.div<{ xPos: string }>`
  //width: initial;
  display: inline-block;
  position: relative;
  justify-content: center;
  align-items: center;
  //width: 263px;
  height: 40px;
  left: ${(props) => props.xPos};
  bottom: 24px;
  font-size: ${FONT_SIZE.xs};
  z-index: 100;

  background: #fff;
  color: var(--text-main-color);
  border-radius: 300px;
  padding: 9px 20px;
  box-shadow: 0px 4px 15px 0px rgba(102, 102, 102, 0.15);

  &:after {
    border-top: 0px solid transparent;
    border-left: 11px solid transparent; /* 더 넓은 뾰족한 부분으로 조정 */
    border-right: 11px solid transparent; /* 더 넓은 뾰족한 부분으로 조정 */
    border-bottom: 20px solid #fff;
    content: '';
    position: absolute;
    top: -18px; /* 말풍선을 상단으로 이동시킴 */ //
    left: 130px; /* 중앙 정렬 */ // kakao 130px
  }
`;

export const ForgotPasswordLinkText = styled(Link)`
  ${Text};
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.9;
  }
`;
