import Hls from 'hls.js';
import { toBlobURL } from '@ffmpeg/util';

function extractUidPrefixUrl(manifestUrl: string) {
  const result = manifestUrl.match(/^(.+)\/([a-f0-9]+)\/manifest\/video.m3u8$/);
  if (result === null || result.length !== 3) {
    throw new Error('Invalid manifest url');
  }

  return { baseUrl: result[1], uid: result[2] };
}

async function retrieveMasterPlaylist(video: { [key: string]: any }) {
  const hls = new Hls();
  hls.loadSource(video.masterManifestUrl);

  const promise = new Promise(function (resolve, reject) {
    hls.on(Hls.Events.MANIFEST_PARSED, (eventName, data) => {
      resolve(data);
    });
  });
  const data = await promise;
  if (!data) {
    throw new Error('Invalid manifest data');
  }
  return data;
}

function retrieveResolution(video: { [key: string]: any }) {
  let url = '';
  let maxWidth = 0;
  let maxHeight = 0;
  let videoCodec = '';

  for (const level of video.data.levels) {
    if (level.width > maxWidth) {
      url = level.uri;
      maxWidth = level.width;
      maxHeight = level.height;
      videoCodec = level.videoCodec;
    }
  }
  return { curManifestUrl: url, width: maxWidth, height: maxHeight, videoCodec };
}

async function retrieveSegmentsFromManifest(video: { [key: string]: any }, manifestUrl: string) {
  if (!manifestUrl) {
    return null;
  }

  const hls = new Hls();
  hls.loadSource(manifestUrl);

  const promise = new Promise(function (resolve, reject) {
    hls.on(Hls.Events.LEVEL_LOADED, (eventName, data) => {
      resolve(data);
    });
  });
  const data: any = await promise;
  if (!data) {
    throw new Error('Invalid manifest data');
  }
  const blobs: Blob[] = [];
  for (const fragment of data.details?.fragments) {
    const segmentUrl = fragment.relurl.replace(/^..\//, '');
    const completeSegmentUrl = `${video.baseUrl}/${segmentUrl}`;
    blobs.push(await retrieveFile(completeSegmentUrl));
  }

  return new Blob(blobs);
}

async function retrieveFile(url: string) {
  const response = await fetch(url);
  return response.blob();
}

function downloadFile(blob: Blob, fileName: string) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
}

const toBlobURLPatched = async (url: string, mimeType: string, patcher: any) => {
  const resp = await fetch(url);
  let body = await resp.text();
  if (patcher) body = patcher(body);
  const blob = new Blob([body], { type: mimeType });
  return URL.createObjectURL(blob);
};

// manifestUrl 은 video.m3u8 파일 링크로 해주세요..
export async function processDownload(manifestUrl: string) {
  try {
    const { baseUrl, uid } = extractUidPrefixUrl(manifestUrl);

    const video: { [key: string]: any } = {
      masterManifestUrl: manifestUrl,
      baseUrl: baseUrl,
      videoUid: uid,
    };

    const data = await retrieveMasterPlaylist(video);
    video.data = data;

    const { curManifestUrl, width, height, videoCodec } = retrieveResolution(video);

    const blobs: Blob[] = [];
    for (const ac of video.data.audioTracks) {
      const blob = await retrieveSegmentsFromManifest(video, ac.url);
      if (blob) {
        blobs.push(blob);
      }
    }

    const videoBlob = await retrieveSegmentsFromManifest(video, curManifestUrl);
    if (videoBlob) {
      const fileName = `${video.videoUid}.mp4`;
      if (video.data.audioTracks.length > 0) {
        const audioBlob = new Blob(blobs, { type: 'audio/mp4' });

        const baseURLFFMPEG = 'https://unpkg.com/@ffmpeg/ffmpeg@0.12.6/dist/umd';
        const ffmpegBlobURL = await toBlobURLPatched(`${baseURLFFMPEG}/ffmpeg.js`, 'text/javascript', (js: string) =>
          js.replace('new URL(e.p+e.u(814),e.b)', 'r.workerLoadURL'),
        );
        await import(/* webpackIgnore: true */ ffmpegBlobURL);
        // @ts-ignore
        const ffmpeg = new FFmpegWASM.FFmpeg();
        ffmpeg.on('log', ({ message }: { message: string }) => {
          console.log(message);
        });

        // This code was written referring to https://github.com/ffmpegwasm/ffmpeg.wasm/discussions/580

        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.3/dist/umd';
        await ffmpeg.load({
          workerLoadURL: await toBlobURL(`${baseURLFFMPEG}/814.ffmpeg.js`, 'text/javascript'),
          coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
          wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
        });
        await ffmpeg.writeFile('audio.mp4', new Uint8Array(await audioBlob.arrayBuffer()));
        await ffmpeg.writeFile('video.mp4', new Uint8Array(await videoBlob.arrayBuffer()));
        await ffmpeg.exec(['-i', 'audio.mp4', '-i', 'video.mp4', '-c:a', 'copy', '-c:v', 'copy', 'output.mp4']);
        const fileData = await ffmpeg.readFile('output.mp4');
        const data = new Uint8Array(fileData as ArrayBuffer);
        const outputBlob = new Blob([data.buffer], { type: 'video/mp4' });
        downloadFile(outputBlob, fileName);
      } else {
        downloadFile(videoBlob, fileName);
      }
    }
  } catch (e: any) {
    console.log(e.message);
  }
}
