import styled from 'styled-components';
import { FormTitle } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';

export const LoginDescription = styled.div`
  margin-bottom: 1rem;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};

  @media only screen and ${media.xs} {
    margin-bottom: 1.5625rem;
    font-weight: ${FONT_WEIGHT.bold};
  }

  @media only screen and ${media.md} {
    margin-bottom: 1.5rem;
    font-weight: ${FONT_WEIGHT.regular};
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1.5rem;
  }
`;

export const Title = styled(FormTitle)`
  margin-bottom: 1.875rem;
  color: #020a33;
  font-style: normal;
  font-weight: 500;
`;
