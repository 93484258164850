import styled from 'styled-components';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

export const CardWrapper = styled.div<{ isActive: boolean }>`
  padding: 16px 64px;
  border-radius: 16px;
  border: ${({ isActive }) => (isActive ? '1px solid #556aeb' : 'none')};
  background: ${({ isActive }) => (isActive ? '#ebefff' : '#F8F9FA')};
  cursor: pointer;

  display: flex;
  gap: 20px;
  color: #adb5bd;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  .name {
    color: #212529;
  }
`;

export const BaseTabWrapper = styled.span<{ isActive: boolean }>`
  font-family: Noto Sans KR sans-serif;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  padding: 6px 24px;
  border-radius: 100px;
  background: #1a8bae;
  background: ${({ isActive }) => (isActive ? '#1a8bae' : '#D6FEF3')};
  border: ${({ isActive }) => (isActive ? 'none' : '1px solid #1A8BAE')};

  color: ${({ isActive }) => (isActive ? '#fff' : '#495057')};
`;

export const ResultCardItemWrapper = styled.div<{ background: string; statusBackground: string; status: number }>`
  padding: 16px;
  border-radius: 16px;
  background: var(--White, #fff);
  width: 287px;
  cursor: ${({ status }) => (status === 2 ? 'pointer' : 'not-allowed')};

  display: flex;
  flex-direction: column;
  .status-tag-lists {
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }
  .info {
    padding: 16px 20px;
    background: ${({ background }) => background};
    border-radius: 8px;
    border: 1px solid #6c757d;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .status-info {
      padding: 4px 16px;
      margin-right: 16px;
    }
    }

    .month {
      color: #495057;
      text-align: center;

      font-size: 24px;
      font-style: normal;
      font-weight: 500;
    }

    .status {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: ${({ statusBackground }) => statusBackground};
      border-radius: 50%;
      margin-right: 8px;
        position: relative;
        top: -2px;
    }
  }
`;

export const BaseStatusTagWrapper = styled.span`
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid #adb5bd;
  background: #fff;
  max-width: 76px;
  box-sizing: border-box;

  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 24px; /* 171.429% */
  text-align: center;
`;
