import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'config/config';
import { Provider } from 'react-redux';
import { store } from '@app/store/store';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import createApolloClient from './hocs/ApolloProvider';
import { MockedProvider } from '@apollo/client/testing';
import { mocks } from '@app/hocs/gql';

interface EventTarget {
  state?: 'activated';
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ApolloProvider client={createApolloClient}>
      {/*<MockedProvider mocks={mocks} addTypename={false}>*/}
      <App />
      {/*</MockedProvider>*/}
    </ApolloProvider>
  </Provider>,
  // </React.StrictMode>
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if ((event.target as EventTarget).state === 'activated') window.location.reload();
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
}); // app will reload if new version of app is available

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
