import { BaseModalProps } from '@app/interfaces/interfaces';
import * as S from './TermModal.styles';
export default function TermModal({ width, open, onClose, title }: BaseModalProps) {
  return (
    <S.ModalWrapper title={title} footer={null} width={width} onCancel={onClose} open={open}>
      <p>
        1. 동의자: 성별, 나이, 거주 지역, 검사일 <br /> 2. 개인정보를 제공받는 기관: 서울아이발달지원센터 <br /> 3.
        개인정보 파일 수집 및 이용목적: 정신건강 서비스 제공 및 정신건강사업 관련 업무 수행 <br /> 4. 제공하는
        개인정보의 항목: 성별, 나이, 거주 지역 이외 대상자가 제공한 정보 <br /> 5. 개인정보 보유 및 이용 기간: 동의
        일로부터 정신건강 서비스 종료일까지 이용, 정신건강사업지침에 근거 등록자 관련 기록보관 10년에 따라 보관 후
        폐기함 <br /> 6. 개인정보처리의 위탁: 정신건강관리 시스템의 제공 서비스에 관련 전자기록 관리 및 시스템 유지 보수
        등 전산에 관련된 부대 업무 <br /> 7. 제3자에게 제공: 타 기관 서비스 연계 처리 시 <br /> 8. 제3자에게 제공 시
        개인정보 보유 및 이용 기간: 수집 이용 동의 일로부터 개인정보의 수집 이용목적 달성 시까지 <br /> 9. 개인정보 동의
        확인 여부: 개인정보 제공 동의서는 동의하지 않을 수 있음.
      </p>

      <S.Desc>
        본인은 서울아이발달지원센터가 제공하는 정신건강 서비스에 대해 충분히 이해하였으며, 개인정보보호법 제15조 및
        제17조의 규정에 의하여 본인의 개인정보를 제공할 것을 동의합니다.
      </S.Desc>
    </S.ModalWrapper>
  );
}
