import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useEffect, useState } from 'react';
import * as S from './TargetResultView.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseFormTitle } from '@app/components/common/forms/components/BaseFormTitle/BaseFormTitle';
import { useReactiveVar } from '@apollo/client';
import { TargetPdfData, targetPdfData } from '@app/hocs/cache';
import { useNavigate, useSearchParams } from 'react-router-dom';
import backImg from '@app/assets/images/back_button.svg';
import next_arrow_img from '@app/assets/images/next_arrow.png';
import prev_arrow_img from '@app/assets/images/prev_arrow.png';
import JSZip from 'jszip';
import { PDFDocument } from 'pdf-lib';
import { notificationController } from '@app/controllers/notificationController';
import printJS from 'print-js';
// const test = [
//   {
//     exMonth: '25',
//     growthResult: 2,
//     statusTag: ['#테스트2'],
//     status: 2,
//     pdfFileUrl: 'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/pdf/1700743633724-177159852ec1394',
//     pdfImages: [
//       'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/images/1700743633482-f0b0d09c52247656',
//       'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/images/1700743633634-3ca128967ea92a26',
//     ],
//     name: '김한신',
//   },
//   {
//     exMonth: '26',
//     growthResult: 2,
//     statusTag: ['#테스트2'],
//     status: 2,
//     pdfFileUrl: 'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/pdf/1700743633724-177159852ec1394',
//     pdfImages: [
//       'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/images/1700743633482-f0b0d09c52247656',
//       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGQ5rl0J8n2myZkotIPC6Ay5DWIB0UcVXgW-I68792t41c5y4X9GhqnEBEsH6J5trX7Is&usqp=CAU',
//     ],
//     name: '김한신',
//   },
//   {
//     exMonth: '27',
//     growthResult: 2,
//     statusTag: ['#테스트2'],
//     status: 2,
//     pdfFileUrl: 'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/pdf/1700743633724-177159852ec1394',
//     pdfImages: [
//       'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/images/1700743633482-f0b0d09c52247656',
//       'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/images/1700743633634-3ca128967ea92a26',
//     ],
//     name: '김한신',
//   },
//   {
//     exMonth: '28',
//     growthResult: 2,
//     statusTag: ['#테스트2'],
//     status: 2,
//     pdfFileUrl: 'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/pdf/1700743633724-177159852ec1394',
//     pdfImages: [
//       'https://mbi-pdf-assets.s3.ap-northeast-2.amazonaws.com/images/1700743633482-f0b0d09c52247656',
//       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGQ5rl0J8n2myZkotIPC6Ay5DWIB0UcVXgW-I68792t41c5y4X9GhqnEBEsH6J5trX7Is&usqp=CAU',
//     ],
//     name: '김한신',
//   },
// ];
export default function TargetResultViewPage() {
  const [targetLists, setTargetLists] = useState<TargetPdfData[]>([]);
  const [selectedTarget, setSelectedTarget] = useState<TargetPdfData | null>(null);
  const targetPdfLists = useReactiveVar(targetPdfData);
  const [searchParams] = useSearchParams();
  const [selectedDataIndex, setSelectedDataIndex] = useState(0);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const navigate = useNavigate();

  const exmonthData = searchParams.get('exmonth');
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
  };
  useEffect(() => {
    if (!targetPdfLists?.length) {
      navigate('/target-result-lists');
      return;
    }
    if (exmonthData) {
      const findData = targetPdfLists.filter((item) => exmonthData === String(item.exMonth));
      const dataIndex = targetPdfLists.findIndex((item) => String(item.exMonth) === exmonthData);

      setSelectedDataIndex(dataIndex);
      setSelectedTarget(findData[0]);
    }
  }, []);

  const onPrintHandler = async () => {
    if (targetPdfLists?.length) {
      const pdfUrl = targetPdfLists[selectedDataIndex].pdfFileUrl;
      printJS(pdfUrl, 'pdf'); // PDF 파일을 인쇄합니다.
    }
  };

  const onDownloadHandler = async () => {
    if (targetPdfLists?.length) {
      try {
        setDownloadLoading(true);
        const pdfDoc = await PDFDocument.create();
        const pdfBytes = await fetch(targetPdfLists[selectedDataIndex].pdfFileUrl).then((res) => res.arrayBuffer());
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${targetPdfLists[selectedDataIndex].name}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (err) {
        notificationController.error({ message: '다운로드 중 에러가 발생했습니다.' });
      } finally {
        setDownloadLoading(false);
      }
    }
  };

  const onPrevClick = () => {
    if (selectedDataIndex === 0 && targetPdfLists?.length) {
      setSelectedDataIndex(targetPdfLists?.length - 1);
      return;
    }

    setSelectedDataIndex((prevState) => prevState - 1);
  };

  const onNextClick = () => {
    if (targetPdfLists?.length) {
      if (selectedDataIndex === targetPdfLists!.length - 1) {
        setSelectedDataIndex(0);
        return;
      }

      setSelectedDataIndex((prevState) => prevState + 1);
    }
  };

  console.log(targetPdfLists, 'targetPdfLists');

  return (
    <>
      <PageTitle>지난 검사 결과 기록</PageTitle>

      <S.Wrapper>
        <BaseRow justify="space-between" style={{ position: 'absolute', top: '0px' }}>
          <BaseCol xl={24} md={24}>
            <BaseFormTitle className="title">
              <img
                style={{ cursor: 'pointer' }}
                src={backImg}
                alt={'back button'}
                onClick={() => {
                  navigate('/target-result-lists');
                }}
              />
              {selectedTarget?.name}・심리발달선별검사・
              {targetPdfLists?.length && targetPdfLists[selectedDataIndex].exMonth}개월
            </BaseFormTitle>
          </BaseCol>
        </BaseRow>

        <S.CardWrapper>
          <div className="prev-arrow">
            <img src={prev_arrow_img} alt="img" onClick={onNextClick} />
            <div>다음</div>
          </div>
          <div className="next-arrow">
            <img src={next_arrow_img} alt="img" onClick={onPrevClick} />
            <div>이전</div>
          </div>
          <div className="pdf-lists">
            {/*<Carousel {...settings}>*/}
            {targetPdfLists &&
              targetPdfLists?.length &&
              targetPdfLists[selectedDataIndex]?.pdfImages?.map((image, i) => <img key={i} src={image} />)}
            {/*</Carousel>*/}
          </div>

          <div className="footer">
            <S.BaseButton onClick={onDownloadHandler}>
              {downloadLoading ? 'PDF 다운로드 중...' : 'PDF 다운로드'}
            </S.BaseButton>
            <S.BaseButton onClick={onPrintHandler}>프린트하기</S.BaseButton>
          </div>
        </S.CardWrapper>

        {targetPdfLists?.length && (
          <embed
            type="application/pdf"
            src={targetPdfLists[selectedDataIndex]?.pdfFileUrl}
            id="pdfDocument"
            width="100%"
            height="100%"
            style={{ display: 'none' }}
          />
        )}
      </S.Wrapper>
    </>
  );
}
