import { RadioData } from '@app/interfaces/interfaces';

export const settingTableTagLists = [
  { label: '전체 사용자', value: 'all' },
  { label: '관리자', value: 'admin' },
  { label: '1팀-오프라인', value: '1team' },
  { label: '2팀-온라인', value: '2team' },
  { label: '사용자(학부모)', value: 'parent' },
  { label: '승인 대기', value: 'approvalPending' },
];

export const examTagLists = [
  { label: '전체', value: 3 },
  { label: '대기', value: 0 },
  { label: '검사', value: 1 },
  { label: '완료', value: 2 },
  { label: '기관검사완료', value: 16 },
  { label: '새로', value: 99 },
];

export const gradingTagLists = [
  { label: '전체', value: 3 },
  { label: '대기', value: 1 },
  { label: '채점', value: 0 },
  { label: '완료', value: 9 },
  { label: '예약', value: 6 },
  { label: '발송', value: 2 },
  { label: '실패', value: 11 },
];

export const popOverTagLists = [
  { label: '관리자', value: 'admin' },
  { label: '1팀', value: '1team' },
  { label: '2팀', value: '2team' },
  { label: '학부모', value: 'parent' },
];

const dummyName = ['윤다연', '김윤수', '김현진', '홍길동', '김철수'];
const dummyRegion = ['강남구', '송파구', '금천구', '구로구', '영등포구'];
const dummyGroupName = ['다온유치원', '강남유치원', '송파유치원', '금천유치원', '구로유치원'];
const dummyExcuter = ['박가람', '박누리', '김희재', '이지연', '최연수'];
interface GradingListItem {
  name: string;
  region: string;
  groupName: string;
  excuters: string[];
  key: number;
  status: number;
  targetBirthDate: string;
}
export const dummyGradingLists: GradingListItem[] = [];

for (let i = 0; i < 100; i++) {
  const randomName = dummyName[Math.floor(Math.random() * dummyName.length)];
  const randomRegion = dummyRegion[Math.floor(Math.random() * dummyRegion.length)];
  const randomGroupName = dummyGroupName[Math.floor(Math.random() * dummyGroupName.length)];

  // 무작위 excuterCount를 1에서 6 사이의 값으로 설정
  const randomExcuterCount = Math.floor(Math.random() * 6) + 1; // 1에서 6 사이의 무작위 정수

  const randomExcuters = [];
  for (let j = 0; j < randomExcuterCount; j++) {
    const randomExcuterName = dummyExcuter[Math.floor(Math.random() * dummyExcuter.length)];
    randomExcuters.push(randomExcuterName);
  }

  const randomStatus = Math.floor(Math.random() * 4);
  const randomTargetBirthDate = Math.floor(Math.random() * 40) + 1;

  dummyGradingLists.push({
    name: randomName,
    region: randomRegion,
    groupName: randomGroupName,
    excuters: randomExcuters,
    key: i,
    status: randomStatus,
    targetBirthDate: `${randomTargetBirthDate} 개월`,
  });
}

export const dummyDetailAssessment = [
  {
    status: 0,
    bdDate: '34개월',
  },
  {
    status: 1,
    bdDate: '33개월',
  },
  {
    status: 2,
    bdDate: '34개월',
  },
  {
    status: 3,
    bdDate: '32개월',
  },
  {
    status: 3,
    bdDate: '34개월',
  },
  {
    status: 3,
    bdDate: '34개월',
  },
  {
    status: 3,
    bdDate: '34개월',
  },
  {
    status: 3,
    bdDate: '34개월',
  },
];

export const dummyTableData = [
  {
    key: 0,
    id: '652ba51ae7ce86c3c14e6886',

    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 1,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 2,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 3,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 4,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 5,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 6,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 7,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 8,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 9,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 10,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 11,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 12,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 13,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 14,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 15,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 16,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 17,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 18,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 19,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 20,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 21,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 22,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 23,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 24,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 25,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 26,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 27,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 28,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 29,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 30,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 31,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 32,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 33,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 34,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 35,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 36,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 37,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 38,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 39,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 40,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 41,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 42,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 43,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 44,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
  {
    key: 45,
    id: '652ba51ae7ce86c3c14e6886',
    name: '검사자',
    tags: [
      {
        value: 'exam',
        priority: 0,
      },
      {
        value: '',
        priority: 0,
      },
    ],
    createdAt: '1697359130278',
  },
];

export const dummyAssessmentData = {
  status: 0,
  data: '{"video":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/e6870f7865a9d5df111f3cbd1feb4ed6/watch","thumb":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/e6870f7865a9d5df111f3cbd1feb4ed6/thumbnails/thumbnail.jpg","comment":"","vr":1.7777777777777777,"vwidth":663,"bmonth":24,"exmonth":24}',
  category: 'assess_0',
  title: '검사 수정',
  owner: '640d95376abc26030a953a86',
  id: '6535f1c2172c200f68349dc6',
  executers: [
    {
      id: '653211232cb5d1598871446f',
      user: '652b9a54e7ce86c3c14e682c',
      authorized: 'admin|1team|2team|',
      name: '김윤우',
      email: '',
      category: 'exam',
      data: '{}',
      targets: [],
      __typename: 'YMExecuter',
    },
  ],
  target: {
    id: '652c9b063003bda78cb51ddf',
    category: 'ym_examinee',
    data: '{"uid":"FT23101602","name":"김한신","bdate":"2021-09-17T00:00:00.000Z","sex":1,"epweek":38,"epday":0}',
    title: '김한신',
    owner: '652c8a513003bda78cb51809',
    status: 5,
    reservedAt: '1697422086341',

    __typename: 'YMTarget',
  },
  group: {
    id: '652c9aab3003bda78cb51d71',
    owner: '652c8a513003bda78cb51809',
    code: 'T7147',
    title: '영등포구-한신또래어린이집',
    category: 'ym_exam',
    data: '{"region":"영등포구","code":"T","name":"한신또래어린이집","date":1}',
    __typename: 'YMGroup',
  },
  __typename: 'YMTask',
};

export const calculateResult = (data: any) => {
  let count0 = 0;
  let count1 = 0;
  let count2 = 0;

  for (const key in data) {
    const value = data[key] as number;
    if (value === 0) {
      count0++;
    } else if (value === 1) {
      count1++;
    } else if (value === 2) {
      count2++;
    }
  }

  // if (count0 >= 2) {
  //   return 0;
  // } else if (count1 >= 2 && count0 <= 1) {
  //   return 1;
  // } else {
  //   return 2;
  // }

  if (count0 >= 2) {
    return 0;
  } else if (count1 >= 2 && count0 <= 1) {
    return 1;
  } else if (count2 === 3 && count0 === 1) {
    return 1; // 2값이 3개이고 0값이 하나 있는 경우
  } else if (count2 === 2 && count1 === 1 && count0 === 1) {
    return 1; // 2값이 2개, 1값이 1개, 0값이 1개인 경우
  } else {
    return 2;
  }
};

export const getColorByTotalScore = (value: number) => {
  if (value === 2) {
    return '#10C34C';
  } else if (value === 1) {
    return '#FCE64C';
  } else if (value === 0) {
    return '#C50000';
  }
  return '#fff';
};

export const getColorByTotal = (value: number) => {
  if (value === 2) {
    return '#10C34C';
  } else if (value === 1) {
    return '#FCE64C';
  } else if (value === 0) {
    return '#C50000';
  }
  return '#fff';
};

export const getRadioTitle = (value: string) => {
  switch (value) {
    case 'cognition':
      return '인지';

    case 'language':
      return '언어';

    case 'exercise':
      return '운동';

    case 'socialEmotional':
      return '사회정서';

    case 'interaction':
      return '상호작용';

    default:
      return '';
  }
};

export const getDDSTRadioTitle = (value: string) => {
  switch (value) {
    case 'personalSociability':
      return '개인-사회성';

    case 'language':
      return '언어';

    case 'exercise':
      return '운동';

    case 'microExercise':
      return '미세운동';

    default:
      return '';
  }
};

export const targetResultLists = [
  {
    exMonth: '1',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '2',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '3',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '4',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '5',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '6',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '7',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '8',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '9',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '10',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '11',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '12',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '13',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '14',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '15',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '16',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '17',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '18',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '19',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '20',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '21',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '22',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '23',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '24',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '25',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '26',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '27',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '28',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '29',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '30',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '31',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '32',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '33',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '34',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '35',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '36',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '37',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '38',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '39',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '40',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '41',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '42',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '43',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '44',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '45',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
  {
    exMonth: '46',
    growthResult: 0,
    statusTag: ['언어미달', '언어미달', '언어미달'],
    status: 0,
  },
];

export const xAxisLabel = [
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
];
interface MonthTasks {
  [key: string]: string[];
}
export const monthTasks: MonthTasks = {
  '3': ['자유 놀이', '양육자 보고 웃기', '누워서 놀기', '시선 따라오기'],
  '4': ['자유 놀이', '엎드려 고개 들기 (터미타임)', '색깔에 대해 관심보이기', '소리에 반응하기'],
  '5': ['자유 놀이', '간식(치발기) 입으로 가져가기', '거울놀이 하기(1)', '뒤집기'],
  '6': ['자유 놀이', '양육자 손잡고 일어나 앉기(혼자 앉기)', '옹알이하기', '손, 발 관찰하기'],
  '7': ['자유 놀이', '딸랑이 놀이', '동물노래 들려주기', '떨어진 물건 보기'],
  '8': ['자유 놀이', '두드리기 놀이', '헝겊공 묶어 흔들기', '혼자 앉아 양육자에게 팔뻗기'],
  '9': ['자유 놀이', '사회적 미소 짓기', '부정어 반응하기', '스스로 기기'],
  '10': ['자유 놀이', '줄을 잡아 물건 당기기', '붙잡고 서기', '양육자 말(소리) 모방하기'],
  '11': ['자유 놀이', '사회성놀이 하기', '컵 안에 작은 물건 넣기', '붙잡고 걷기', '호명/눈맞춤 반응하기'],
  '12': ['자유 놀이', '혼자 서있기', '팝업놀이 하기', '애착반응 확인하기', '말하듯이 옹알이하기'],
  '13': ['자유 놀이', '혼자 걷기', '단어 표현하기(1)', '포인팅 하기', '과자 조각 꺼내기'],
  '14': ['자유 놀이', '물건 넣고 빼기', '간단한 상징놀이하기', '자기 이름과 타인 이름 구별하기', '집게 손가락 잡기'],
  '15': ['자유 놀이', '숨겨진 물건 찾기', '거울놀이 하기(2)', '낙서하기', '기저귀 가져오기'],
  '16': ['자유 놀이', '모양 끼우기(1)', '"바이바이"하기', '공던지기 놀이 하기', '제스처와 단어 함꼐 사용하기'],
  '17': ['자유 놀이', '빗질 따라하기', '그림책에 있는 물건 찾기', '곰인형 찾기 놀이하기', '도움 없이 쪼그려 앉았다가 일어나기'],
  '18': ['자유 놀이', '단어 표현하기(2)', '거울놀이하기(3)', '양육자 물건 찾아 보여주기', '뒤로 걷기'],
  '19': ['자유 놀이', '상징놀이하기', '"코코코~" 놀이하기', '공감반응 보이기', '제자리 점프하기'],
  '20': ['자유 놀이', '두가지 이상의 지시에 따르기', '"응", "아니"로 의사 표현하기', '계단 오르기','생일 케이크 초 꽂기'],
  '21': ['자유 놀이', '그림책에 있는 것을 가리키면서 이야기하기', '책장 넘기기', '가리키는 것 보기', '병뚜껑 열기'],
  '22': ['자유 놀이', '옷 종류 알아맞히기', '관심 요구하기', '모양 끼우기(2)', '블록 4개 쌓기'],
  '23': ['자유 놀이', '"이게 뭐야" 질문하기', '두 조각 퍼즐 맞추기', '레고 블록 빼고 끼우기', '생일축하 놀이하기'],
  '24': ['자유 놀이', '"이게 뭐야" 질문하기', '두 조각 퍼즐 맞추기', '레고 블록 빼고 끼우기', '생일축하 놀이하기'],
  '25': ['자유 놀이', '집안일 돕기', '질문에 대답하기', '이야기를 집중하여 듣기', '수직선 긋기'],
  '26': ['자유 놀이', '집안일 돕기', '질문에 대답하기', '이야기를 집중하여 듣기', '수직선 긋기'],
  '27': ['자유 놀이', '공 차기', '문장 표현하기', '재롱 부리기', '똑같은 그림 찾기'],
  '28': ['자유 놀이', '공 차기', '문장 표현하기', '재롱 부리기', '똑같은 그림 찾기'],
  '29': ['자유 놀이', '공 차기', '문장 표현하기', '재롱 부리기', '똑같은 그림 찾기'],
  '30': ['자유 놀이', '공 차기', '문장 표현하기', '재롱 부리기', '똑같은 그림 찾기'],
  '31': ['자유 놀이', '위치 맞추기', '똑같은 색깔 찾기', '한발 서기', '상황에 적절한 인삿말하기'],
  '32': ['자유 놀이', '위치 맞추기', '똑같은 색깔 찾기', '한발 서기', '상황에 적절한 인삿말하기'],
  '33': ['자유 놀이', '위치 맞추기', '똑같은 색깔 찾기', '한발 서기', '상황에 적절한 인삿말하기'],
  '34': ['자유 놀이', '위치 맞추기', '똑같은 색깔 찾기', '한발 서기', '상황에 적절한 인삿말하기'],
  '35': ['자유 놀이', '역할놀이하기', '수 세기', '크기와 무게 비교하기', '가위질 하기'],
  '36': ['자유 놀이', '역할놀이하기', '수 세기', '크기와 무게 비교하기', '가위질 하기'],
  '37': ['자유 놀이', '역할놀이하기', '수 세기', '크기와 무게 비교하기', '가위질 하기'],
  '38': ['자유 놀이', '역할놀이하기', '수 세기', '크기와 무게 비교하기', '가위질 하기'],
  '39': ['자유 놀이', '두 발 모아 앞으로 뛰기', '사물의 용도 말하기', '길이 구분하기', '대화 주고받기'],
  '40': ['자유 놀이', '두 발 모아 앞으로 뛰기', '사물의 용도 말하기', '길이 구분하기', '대화 주고받기'],
  '41': ['자유 놀이', '두 발 모아 앞으로 뛰기', '사물의 용도 말하기', '길이 구분하기', '대화 주고받기'],
  '42': ['자유 놀이', '두 발 모아 앞으로 뛰기', '사물의 용도 말하기', '길이 구분하기', '대화 주고받기'],
  '43': ['자유 놀이', '십자모양 따라 그리기', '질문에 대답하기', '많고 적음 구별하기', '함께 놀이하기'],
  '44': ['자유 놀이', '십자모양 따라 그리기', '질문에 대답하기', '많고 적음 구별하기', '함께 놀이하기'],
  '45': ['자유 놀이', '십자모양 따라 그리기', '질문에 대답하기', '많고 적음 구별하기', '함께 놀이하기'],
  '46': ['자유 놀이', '십자모양 따라 그리기', '질문에 대답하기', '많고 적음 구별하기', '함께 놀이하기'],
};

export const onlineTasks: { [key: string]: { category: string; title: string }[] } = {
  '3': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '양육자 보고 웃기',
    },
    {
      category: 'e_2',
      title: '누워서 놀기',
    },
    {
      category: 'e_3',
      title: '시선 따라오기',
    },
  ],
  '4': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '엎드려 고개 들기 (터미타임)',
    },
    {
      category: 'e_2',
      title: '색깔에 대해 관심보이기',
    },
    {
      category: 'e_3',
      title: '소리에 반응하기',
    },
  ],
  '5': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '간식(치발기) 입으로 가져가기',
    },
    {
      category: 'e_2',
      title: '거울놀이 하기(1)',
    },
    {
      category: 'e_3',
      title: '뒤집기',
    },
  ],
  '6': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '양육자 손잡고 일어나 앉기(혼자 앉기)',
    },
    {
      category: 'e_2',
      title: '옹알이하기',
    },
    {
      category: 'e_3',
      title: '손, 발 관찰하기',
    },
  ],
  '7': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '딸랑이 놀이',
    },
    {
      category: 'e_2',
      title: '동물노래 들려주기',
    },
    {
      category: 'e_3',
      title: '떨어진 물건 보기',
    },
  ],
  '8': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '두드리기 놀이',
    },
    {
      category: 'e_2',
      title: '헝겊공 묶어 흔들기',
    },
    {
      category: 'e_3',
      title: '혼자 앉아 양육자에게 팔뻗기',
    },
  ],
  '9': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '사회적 미소 짓기',
    },
    {
      category: 'e_2',
      title: '부정어 반응하기',
    },
    {
      category: 'e_3',
      title: '스스로 기기',
    },
  ],
  '10': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '줄을 잡아 물건 당기기',
    },
    {
      category: 'e_2',
      title: '붙잡고 서기',
    },
    {
      category: 'e_3',
      title: '양육자 말(소리) 모방하기',
    },
  ],
  '11': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '사회성놀이 하기',
    },
    {
      category: 'e_2',
      title: '컵 안에 작은 물건 넣기',
    },
    {
      category: 'e_3',
      title: '붙잡고 걷기',
    },
    {
      category: 'e_4',
      title: '호명/눈맞춤 반응하기',
    },
  ],
  '12': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '혼자 서있기',
    },
    {
      category: 'e_2',
      title: '팝업놀이 하기',
    },
    {
      category: 'e_3',
      title: '애착반응 확인하기',
    },
    {
      category: 'e_4',
      title: '말하듯이 옹알이하기',
    },
  ],
  '13': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '혼자 걷기',
    },
    {
      category: 'e_2',
      title: '단어 표현하기(1)',
    },
    {
      category: 'e_3',
      title: '포인팅 하기',
    },
    {
      category: 'e_4',
      title: '과자 조각 꺼내기',
    },
  ],
  '14': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '물건 넣고 빼기',
    },
    {
      category: 'e_2',
      title: '간단한 상징놀이하기',
    },
    {
      category: 'e_3',
      title: '자기 이름과 타인 이름 구별하기',
    },
    {
      category: 'e_4',
      title: '집게 손가락 잡기',
    },
  ],
  '15': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '숨겨진 물건 찾기',
    },
    {
      category: 'e_2',
      title: '거울놀이 하기(2)',
    },
    {
      category: 'e_3',
      title: '낙서하기',
    },
    {
      category: 'e_4',
      title: '기저귀 가져오기',
    },
  ],
  '16': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '모양 끼우기(1)',
    },
    {
      category: 'e_2',
      title: '"바이바이"하기',
    },
    {
      category: 'e_3',
      title: '공던지기 놀이 하기',
    },
    {
      category: 'e_4',
      title: '제스처와 단어 함꼐 사용하기',
    },
  ],
  '17': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '빗질 따라하기',
    },
    {
      category: 'e_2',
      title: '그림책에 있는 물건 찾기',
    },
    {
      category: 'e_3',
      title: '곰인형 찾기 놀이하기',
    },
    {
      category: 'e_4',
      title: '도움 없이 쪼그려 앉았다가 일어나기',
    },
  ],
  '18': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '단어 표현하기(2)',
    },
    {
      category: 'e_2',
      title: '거울놀이하기(3)',
    },
    {
      category: 'e_3',
      title: '양육자 물건 찾아 보여주기',
    },
    {
      category: 'e_4',
      title: '뒤로 걷기',
    },
  ],
  '19': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '상징놀이하기',
    },
    {
      category: 'e_2',
      title: '"코코코~" 놀이하기',
    },
    {
      category: 'e_3',
      title: '공감반응 보이기',
    },
    {
      category: 'e_4',
      title: '제자리 점프하기',
    },
  ],
  '20': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '두가지 이상의 지시에 따르기',
    },
    {
      category: 'e_2',
      title: '"응", "아니"로 의사 표현하기',
    },
    {
      category: 'e_3',
      title: '계단 오르기',
    },
    {
      category: 'e_4',
      title: '생일 케이크 초 꽂기',
    },
  ],
  '21': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '그림책에 있는 것을 가리키면서 이야기하기',
    },
    {
      category: 'e_2',
      title: '책장 넘기기',
    },
    {
      category: 'e_3',
      title: '가리키는 것 보기',
    },
    {
      category: 'e_4',
      title: '병뚜껑 열기',
    },
  ],
  '22': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '옷 종류 알아맞히기',
    },
    {
      category: 'e_2',
      title: '관심 요구하기',
    },
    {
      category: 'e_3',
      title: '모양 끼우기(2)',
    },
    {
      category: 'e_4',
      title: '블록 4개 쌓기',
    },
  ],
  '23': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '"이게 뭐야" 질문하기',
    },
    {
      category: 'e_2',
      title: '두 조각 퍼즐 맞추기',
    },
    {
      category: 'e_3',
      title: '레고 블록 빼고 끼우기',
    },
    {
      category: 'e_4',
      title: '생일축하 놀이하기',
    },
  ],
  '24': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '"이게 뭐야" 질문하기',
    },
    {
      category: 'e_2',
      title: '두 조각 퍼즐 맞추기',
    },
    {
      category: 'e_3',
      title: '레고 블록 빼고 끼우기',
    },
    {
      category: 'e_4',
      title: '생일축하 놀이하기',
    },
  ],
  '25': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '집안일 돕기',
    },
    {
      category: 'e_2',
      title: '질문에 대답하기',
    },
    {
      category: 'e_3',
      title: '이야기를 집중하여 듣기',
    },
    {
      category: 'e_4',
      title: '수직선 긋기',
    },
  ],
  '26': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '집안일 돕기',
    },
    {
      category: 'e_2',
      title: '질문에 대답하기',
    },
    {
      category: 'e_3',
      title: '이야기를 집중하여 듣기',
    },
    {
      category: 'e_4',
      title: '수직선 긋기',
    },
  ],
  '27': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '공 차기',
    },
    {
      category: 'e_2',
      title: '문장 표현하기',
    },
    {
      category: 'e_3',
      title: '재롱 부리기',
    },
    {
      category: 'e_4',
      title: '똑같은 그림 찾기',
    },
  ],
  '28': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '공 차기',
    },
    {
      category: 'e_2',
      title: '문장 표현하기',
    },
    {
      category: 'e_3',
      title: '재롱 부리기',
    },
    {
      category: 'e_4',
      title: '똑같은 그림 찾기',
    },
  ],
  '29': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '공 차기',
    },
    {
      category: 'e_2',
      title: '문장 표현하기',
    },
    {
      category: 'e_3',
      title: '재롱 부리기',
    },
    {
      category: 'e_4',
      title: '똑같은 그림 찾기',
    },
  ],
  '30': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '공 차기',
    },
    {
      category: 'e_2',
      title: '문장 표현하기',
    },
    {
      category: 'e_3',
      title: '재롱 부리기',
    },
    {
      category: 'e_4',
      title: '똑같은 그림 찾기',
    },
  ],
  '31': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '위치 맞추기',
    },
    {
      category: 'e_2',
      title: '똑같은 색깔 찾기',
    },
    {
      category: 'e_3',
      title: '한발 서기',
    },
    {
      category: 'e_4',
      title: '상황에 적절한 인삿말하기',
    },
  ],
  '32': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '위치 맞추기',
    },
    {
      category: 'e_2',
      title: '똑같은 색깔 찾기',
    },
    {
      category: 'e_3',
      title: '한발 서기',
    },
    {
      category: 'e_4',
      title: '상황에 적절한 인삿말하기',
    },
  ],
  '33': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '위치 맞추기',
    },
    {
      category: 'e_2',
      title: '똑같은 색깔 찾기',
    },
    {
      category: 'e_3',
      title: '한발 서기',
    },
    {
      category: 'e_4',
      title: '상황에 적절한 인삿말하기',
    },
  ],
  '34': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '위치 맞추기',
    },
    {
      category: 'e_2',
      title: '똑같은 색깔 찾기',
    },
    {
      category: 'e_3',
      title: '한발 서기',
    },
    {
      category: 'e_4',
      title: '상황에 적절한 인삿말하기',
    },
  ],
  '35': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '역할놀이하기',
    },
    {
      category: 'e_2',
      title: '수 세기',
    },
    {
      category: 'e_3',
      title: '크기와 무게 비교하기',
    },
    {
      category: 'e_4',
      title: '가위질 하기',
    },
  ],
  '36': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '역할놀이하기',
    },
    {
      category: 'e_2',
      title: '수 세기',
    },
    {
      category: 'e_3',
      title: '크기와 무게 비교하기',
    },
    {
      category: 'e_4',
      title: '가위질 하기',
    },
  ],
  '37': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '역할놀이하기',
    },
    {
      category: 'e_2',
      title: '수 세기',
    },
    {
      category: 'e_3',
      title: '크기와 무게 비교하기',
    },
    {
      category: 'e_4',
      title: '가위질 하기',
    },
  ],
  '38': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '역할놀이하기',
    },
    {
      category: 'e_2',
      title: '수 세기',
    },
    {
      category: 'e_3',
      title: '크기와 무게 비교하기',
    },
    {
      category: 'e_4',
      title: '가위질 하기',
    },
  ],
  '39': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '두 발 모아 앞으로 뛰기',
    },
    {
      category: 'e_2',
      title: '사물의 용도 말하기',
    },
    {
      category: 'e_3',
      title: '길이 구분하기',
    },
    {
      category: 'e_4',
      title: '대화 주고받기',
    },
  ],
  '40': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '두 발 모아 앞으로 뛰기',
    },
    {
      category: 'e_2',
      title: '사물의 용도 말하기',
    },
    {
      category: 'e_3',
      title: '길이 구분하기',
    },
    {
      category: 'e_4',
      title: '대화 주고받기',
    },
  ],
  '41': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '두 발 모아 앞으로 뛰기',
    },
    {
      category: 'e_2',
      title: '사물의 용도 말하기',
    },
    {
      category: 'e_3',
      title: '길이 구분하기',
    },
    {
      category: 'e_4',
      title: '대화 주고받기',
    },
  ],
  '42': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '두 발 모아 앞으로 뛰기',
    },
    {
      category: 'e_2',
      title: '사물의 용도 말하기',
    },
    {
      category: 'e_3',
      title: '길이 구분하기',
    },
    {
      category: 'e_4',
      title: '대화 주고받기',
    },
  ],
  '43': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '십자모양 따라 그리기',
    },
    {
      category: 'e_2',
      title: '질문에 대답하기',
    },
    {
      category: 'e_3',
      title: '많고 적음 구별하기',
    },
    {
      category: 'e_4',
      title: '함께 놀이하기',
    },
  ],
  '44': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '십자모양 따라 그리기',
    },
    {
      category: 'e_2',
      title: '질문에 대답하기',
    },
    {
      category: 'e_3',
      title: '많고 적음 구별하기',
    },
    {
      category: 'e_4',
      title: '함께 놀이하기',
    },
  ],
  '45': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '십자모양 따라 그리기',
    },
    {
      category: 'e_2',
      title: '질문에 대답하기',
    },
    {
      category: 'e_3',
      title: '많고 적음 구별하기',
    },
    {
      category: 'e_4',
      title: '함께 놀이하기',
    },
  ],
  '46': [
    {
      category: 'e_0',
      title: '자유 놀이',
    },
    {
      category: 'e_1',
      title: '십자모양 따라 그리기',
    },
    {
      category: 'e_2',
      title: '질문에 대답하기',
    },
    {
      category: 'e_3',
      title: '많고 적음 구별하기',
    },
    {
      category: 'e_4',
      title: '함께 놀이하기',
    },
  ],
};
