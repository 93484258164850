import styled from 'styled-components';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { Checkbox } from 'antd';

export const Wrapper = styled.div``;

export const Title = styled.div``;

export const TitleWrapper = styled(BaseRow)`
  position: absolute;
  top: 0;

  .form-title {
    //padding: 1rem;
    padding-top: 1rem;
    display: flex;
    gap: 15px;
    align-items: center;
  }
`;

export const TargetInfoBox = styled.div`
  margin-top: 0px;
  background: var(--primary-100, #ebefff);
  padding: 8px 24px;
  display: inline-flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--primary-200, #b9c4ff);
  gap: 50px;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;

  span {
    position: relative;
  }

  span:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: -25px;
    width: 1px;
    height: 100%;
    background-color: var(--primary-200, #b9c4ff);
  }
`;

export const CardWrapper = styled.div`
  margin-top: 25px;
  padding: 0 30px 0 60px;
  position: relative;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
`;

export const CardLists = styled.ul`
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  list-style-type: none;
  align-items: flex-start;
`;

export const BeforeNavigateImg = styled.img`
  z-index: 10;
  position: absolute;
  top: 50%;
  cursor: pointer;
`;

export const NextNavigateImg = styled.img`
  z-index: 10;

  position: absolute;
  top: 50%;
  right: 130px;
  cursor: pointer;
`;

export const CardItem = styled.li`
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-content: flex-start;
  padding: 16px 16px 16px 16px;
  background: var(--White, #fff);
  border-radius: 20px;
  width: 287px;
  position: relative;
  cursor: pointer;

  .before-navigate-img {
    z-index: 100;
    position: absolute;
    top: 50%;
  }
`;

export const StatusDot = styled.span<{ status: number }>`
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => {
    switch (props.status) {
      case 0:
        return '#C50000';
      case 1:
        return '#FCE64C';
      case 2:
        return '#10C34C';

      case 3:
        return '#1A8BAE';

      default:
        return 'var(--secondary-m-700, #1a8bae)';
    }
  }};
`;
const statusBackgroundColor = (status: number) => {
  if (status === 0) {
    return 'var(--primary-100, #ebefff)';
  } else {
    return '#F8F9FA';
  }
};

const statusBorderColor = (status: number) => {
  if (status === 0) {
    return '#556aeb';
  } else {
    return '#6C757D';
  }
};
export const StatusAndBirthDateTag = styled.div<{ status: number }>`
  position: relative;
  display: flex;
  padding: 8px 12px;
  border-radius: 30px;
  border: 1px solid ${(props) => statusBorderColor(props.status)};
  background: ${(props) => statusBackgroundColor(props.status)};
  justify-content: center;
  align-items: center;
  gap: 10px;

  .status-text {
    color: #6c757d;
    font-size: 13px;
    font-weight: 400;
    margin-right: 5px;
  }
  .bd-date {
    font-size: 20px;
    font-weight: 500;
    color: #495057;
  }
`;

export const BottomWrapper = styled.div`
  margin-top: 20px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
`;

export const TaskCheckbox = styled(Checkbox)`
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }
  .ant-checkbox-inner {
    border-radius: 0; // 네모로 만들기
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6c757d; // 체크박스 색상
    border-color: #6c757d; // 체크박스 테두리 색상
  }

  .ant-checkbox-wrapper {
    color: #495057; // 라벨 색상
  }

  .ant-checkbox-wrapper:hover {
    color: #495057; // 라벨의 hover 시 색상
  }
`;

export const TaskLabel = styled.span`
  color: #495057;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
`;

export const TagWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  padding: 8px;
  gap: 8px;
  justify-content: space-between;
`;
export const ResultTag = styled.span`
  padding: 4px 8px;
  border-radius: 100px;
  border: 1px solid var(--neutral-500, #adb5bd);
  background: var(--White, #fff);
  color: #495057;
  font-size: 14px;
  font-weight: 350;
`;

export const SectionWrapper = styled.div`
  position: relative;
`;
