import styled from 'styled-components';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';

export const Description = styled.div`
  margin-bottom: 1.875rem;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};

  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xxs};
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }
`;

export const SubmitButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
  margin-top: 1.125rem;
  margin-bottom: 1rem;
`;

export const PasswordResetSuccessContainer = styled.div`
  box-sizing: border-box;
  padding: 0.8rem 1rem;
  text-align: center;
  h1 {
    font-size: 2rem;
    margin: 0;
  }
  p {
    margin-top: 2.5rem;
    margin-bottom: 0px;
    font-size: 1.125rem;
    font-weight: ${FONT_WEIGHT.regular};
  }
  .confirm-btn {
    margin-top: 48px;
    width: 100%;
  }
`;

export const FormWrapper = styled(Auth.FormWrapper)`
  max-width: 472px;
  margin: 0 auto;

`