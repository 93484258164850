import styled from 'styled-components';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

export const Wrapper = styled.div`
  font-family: 'NotoSansKR', sans-serif;

  .title {
    display: flex;
    align-items: center;
    color: #212529;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    padding: 1rem;

    > img {
      width: 46px;
      margin-right: 24px;
    }
  }

  .target-card-lists {
    display: flex;
    gap: 16px;
  }
`;

export const CardWrapper = styled(BaseCard)`
  margin-top: 40px;
  padding: 24px 64px;
  .ant-card-body {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  position: relative;
  .prev-arrow {
    display: flex;
    align-items: center;
    flex-direction: column;
    > img {
      margin-bottom: 10px;
    }
    position: absolute;
    left: 1%;
    top: 50%;
    cursor: pointer;
    color: #495057;
    font-size: 12px;
  }

  .next-arrow {
    display: flex;
    align-items: center;
    flex-direction: column;
    > img {
      margin-bottom: 10px;
    }
    position: absolute;
    right: 1%;
    top: 50%;
    cursor: pointer;
    color: #495057;
    font-size: 12px;
  }

  .pdf-lists {
    display: flex;
    justify-content: center;
    gap: 24px;

    border: 1.5px solid #6c757d;
    border-radius: 10px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
`;

export const BaseButton = styled.button`
  border-radius: 8px;
  border: 1px solid var(--primary-400, #6e82fe);
  background: var(--primary-100, #ebefff);
  padding: 8px 16px;
  color: #354acb;
  transition: background ease-in 0.3s;

  cursor: pointer;
  margin-top: 16px;

  &:hover {
    color: #fff;
    background: #354acb;
  }
`;
