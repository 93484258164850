import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doResetPassword } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { Button, Input, Modal } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { printCleanupDetails } from 'workbox-precaching/utils/printCleanupDetails';
import { changePasswordMutation, CREATE_USER, RESET_CODEM, RESET_PASSWORD, setMailGQL } from '@app/hocs/gql';
import { generateRandomCode, sendEmail } from '@app/utils/utils';

interface ForgotPasswordFormData {
  email: string;
  resetCode: string;
  password: string;
}

interface ResetPasswordFormData {
  email: string;
  password: string;
  confirmPassword: string;
}
const initValues = {
  email: '',
  password: '',
};

// export const fetchData = (delay) => new Promise((resolve) => setTimeout(() => resolve('data'), delay));
// AWS.config.update({
//   accessKeyId: 'AKIAY5JEW5JOQCJT72SI',
//   secretAccessKey: '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',
//   region: 'ap-northeast-2', // 한국 서울 리전
// });
// AWS.config.update({
//   accessKeyId: 'AKIAY5JEW5JO52TIFUHI',
//   secretAccessKey: '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',
//   region: 'ap-northeast-2', // 한국 서울 리전
// });

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [process, setProcess] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  ('');

  const [resetCode, setResetCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const [confirmModal, setConfirmModal] = useState(false);
  const [createUser] = useMutation(CREATE_USER);
  const [restCode] = useMutation(RESET_CODEM);
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [changePassword] = useMutation(changePasswordMutation);

  const [setMail] = useMutation(setMailGQL, {
    onCompleted(data) {
      if (data.setMail.success !== true) {
        notificationController.error({ message: data.setMail.error });
      } else {
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  // const [mutaion, { data: resetData }] = useMutation(gql`
  //   mutation FakeRestCodeM($code: String!) {
  //     success
  //   }
  // `);
  const onToggleModal = (setModal: React.Dispatch<React.SetStateAction<boolean>>) => {
    setModal((modalState) => !modalState);
  };

  const validatePassword = (rule: any, value: string, callback: (error?: string) => void) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;

    if (!regex.test(value)) {
      callback('영 대소문자, 숫자, 특수문자 조합으로 8~16자.');
    } else {
      callback();
    }
  };

  const handleRestCode = async (values: ForgotPasswordFormData) => {
    const codeValue = '123456';

    if (values.resetCode === resetCode) {
      setProcess(3);
    } else {
      setCodeError('인증코드가 입력한 코드와 다릅니다. 다시 확인해주세요.');
    }

    console.log();
  };

  const handleResetPassword = async (values: ForgotPasswordFormData) => {
    const result = await changePassword({
      variables: {
        email: email,
        newPassword: values.password,
      },
    });
    if (result.data?.changePassword?.success) {
      onToggleModal(setConfirmModal);
    } else {
    }
  };

  const handleSubmit = (values: ForgotPasswordFormData) => {
    // setLoading(true);
    // dispatch(doResetPassword(values))
    //   .unwrap()
    //   .then(() => {
    //     navigate('/auth/security-code');
    //   })
    //   .catch((err) => {
    //     notificationController.error({ message: err.message });
    //     setLoading(false);
    //   });
  };

  const handleResetPasswordSubmit = async (value: ResetPasswordFormData) => {
    try {
      // AWS.config.update({
      //   accessKeyId: 'AKIAY5JEW5JOQCJT72SI',
      //   secretAccessKey: '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',
      //   region: 'ap-northeast-2', // 한국 서울 리전
      // });
      const code = generateRandomCode(6);
      console.log(code, 'code');
      setResetCode(code);
      setEmail(value.email);

      const params = {
        Source: 'seoul-i@seoul-i.kr',
        Destination: {
          ToAddresses: [value.email],
        },
        Message: {
          Subject: {
            Data: '인증코드입니다.',
          },
          Body: {
            Text: {
              Data: `안녕하세요. 인증코드입니다. ${code}`,
            },
          },
        },
        ConfigurationSetName: 'EmailTrackingConfig'
      };

      const mid = await sendEmail(params,null);
      if( mid !== ''){
        
        const key = ''+(new Date()).getTime();
        await setMail({
          variables: {
            type: 3,
            tid: '',
            key,
            mailid:mid,
            emails: value.email,
            status: 7,
            body:JSON.stringify(params),
          },
        });
      }

      console.log('success');
      setProcess(1);
    } catch (error) {
      // 뮤테이션 오류 처리
      console.error(error);
    }
  };

  return (
    <S.FormWrapper>
      <BaseForm
        layout="vertical"
        onFinish={
          process === 0
            ? handleResetPasswordSubmit
            : process === 1
            ? handleRestCode
            : process === 3
            ? handleResetPassword
            : handleSubmit
        }
        requiredMark="optional"
        initialValues={initValues}
      >
        {/*<Auth.BackWrapper onClick={() => navigate(-1)}>*/}
        {/*  <Auth.BackIcon />*/}
        {/*  {t('common.back')}*/}
        {/*</Auth.BackWrapper>*/}
        <Auth.FormTitle>비밀번호 재설정</Auth.FormTitle>
        {process === 0 ? (
          <S.Description>
            괜찮아요☺️ 저희도 자주 까먹어요. 쉽게 재설정 할 수 있도록 도와드릴게요. 가입할 때 사용한 이메일 주소를
            입력하시면 인증코드가 발송됩니다.
          </S.Description>
        ) : process === 1 ? (
          <S.Description>인증코드 발송 완료! 이메일의 인증코드를 입력해 주세요.</S.Description>
        ) : (
          <S.Description>인증에 성공했어요🥳 새로운 비밀번호를 입력해 주세요.</S.Description>
        )}
        {process === 3 ? (
          <>
            <Auth.FormItem
              name="password"
              label={'Password'}
              rules={[
                {
                  required: true,
                  message: '패스워드를 입력해주세요!',
                },
                {
                  validator: validatePassword, // 패스워드 유효성 검사 함수
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="새로운 비밀번호 입력" />
            </Auth.FormItem>
            <Auth.Text>영 대소문자, 숫자, 특수문자 조합으로 8~16자.</Auth.Text>
            <Auth.FormItem
              name="confirm"
              label={'Password'}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: '비밀번호 확인을 입력해주세요!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('입력한 비밀번호가 일치하지 않습니다!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="새로운 비밀번호 확인" />
            </Auth.FormItem>
          </>
        ) : (
          <>
            <Auth.FormItem
              name="email"
              label="Email"
              rules={[
                {
                  type: 'email',
                  message: '올바른 이메일 형식이 아닙니다.',
                },
                { required: true, message: t('이메일을 입력해주세요.') },
              ]}
            >
              {/*<FloatLabel label="고유번호" name="select" required />*/}
              <Auth.FormInput disabled={process !== 0} placeholder={t('common.email')} />
            </Auth.FormItem>

            {process === 1 && (
              <Auth.FormItem
                name="resetCode"
                label="Reset Code"
                rules={[{ required: true, message: t('Reset Code를 입력해주세요.') }]}
                validateStatus={codeError ? 'error' : ''}
                help={codeError}
              >
                <Auth.FormInputPassword placeholder={t('인증코드 입력')} />
              </Auth.FormItem>
            )}
          </>
        )}
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {process === 3 ? t('재설정하기') : t('확인')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>

      <Modal open={confirmModal} footer={null} onCancel={() => onToggleModal(setConfirmModal)} width="23rem">
        <S.PasswordResetSuccessContainer>
          <div>
            <h1>재설정 완료 🎉</h1>
            <p>
              성공적으로 비밀번호를 재설정 했어요. <br />
              로그인 페이지로 이동합니다.
            </p>

            <Button
              type="primary"
              className="confirm-btn"
              onClick={() => {
                onToggleModal(setConfirmModal);
                navigate('/auth/login');
                setProcess(0);
              }}
            >
              확인
            </Button>
          </div>
        </S.PasswordResetSuccessContainer>
      </Modal>
    </S.FormWrapper>
  );
};
