import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ScreeningsHeader } from '../ScreeningsHeader/ScreeningsHeader';
import { ScreeningsFriends } from '../screeningsFriends/ScreeningsFriends/ScreeningsFriends';
import { ScreeningsChart } from '../ScreeningsChart/ScreeningsChart';
import { getScreenings, Screening } from '@app/api/screenings.api';
import { AppDate, Dates } from '@app/constants/Dates';
import { getStatistics, Statistic } from '@app/api/statistics.api';
import { getSmoothRandom } from '@app/utils/utils';
import { Doctor, getDoctorsData } from '@app/api/doctors.api';
import * as S from  './ScheduleCard.styles';
import { ScheduleChart } from '../ScheduleChart/ScheduleChart';
import { CalendarEvent } from '@app/api/calendar.api';
import { TreatmentCalendar } from '../../treatmentCard/TreatmentCalendar/TreatmentCalendar';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { TreatmentPanel } from '../../treatmentCard/TreatmentPanel';
import styled from 'styled-components';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { ScheduleHeader } from '../ScheduleHeader/ScheduleHeader';

export interface CurrentStatisticsState {
  firstUser: number;
  secondUser: number;
  month: number;
  statistic: number;
}

export type ScreeningWithDoctors = Screening & { name: string; imgUrl: string };

export const ScheduleCard: React.FC = () => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [statistics, setStatistics] = useState<Statistic[]>([]);
  const [screenings, setScreenings] = useState<Screening[]>([]);
  const [currentStatistics, setCurrentStatistics] = useState<CurrentStatisticsState>({
    firstUser: 1,
    secondUser: 3,
    month: Dates.getToday().get('month'),
    statistic: 2,
  });
  const [isFirstClick, setFirstClick] = useState(true);

  useEffect(() => {
    getScreenings().then((res) => setScreenings(res));
  }, []);

  useEffect(() => {
    getStatistics().then((res) => setStatistics(res));
  }, []);

  useEffect(() => {
    getDoctorsData().then((res) => setDoctors(res));
  }, []);

  const months = useMemo(() => Array.from({ length: 12 }, (_, i) => i), []);

  const screeningsWithDoctors = useMemo((): ScreeningWithDoctors[] => {
    return screenings.map((screening) => {
      const currentDoctor = doctors.find((doctor) => doctor.id === screening.id);

      return {
        ...screening,
        name: currentDoctor?.name || '',
        imgUrl: currentDoctor?.imgUrl || '',
      };
    });
  }, [doctors, screenings]);

  const generateScreeningValue = () => {
    const randomValue = getSmoothRandom(3, 0.7) * 100;
    return (randomValue * Math.abs(Math.sin(randomValue))).toFixed();
  };

  const values = useMemo(
    () =>
      months.map((month) => ({
        monthId: month,
        data: statistics.map((statistic) => ({
          statisticId: statistic.id,
          data: screenings.map((screening) => ({
            id: screening.id,
            data: Array.from({ length: 16 }, (_, index) => ({
              day: index * 2,
              value: generateScreeningValue(),
            })),
          })),
        })),
      })),
    [months, screenings, statistics],
  );

  const currentValues = useMemo(
    () =>
      values
        .find((month) => month.monthId === currentStatistics.month)
        ?.data.find((statistic) => statistic.statisticId === currentStatistics.statistic)?.data,
    [currentStatistics.month, currentStatistics.statistic, values],
  );

  const getUserStatistic = useCallback(
    (isFirstUser: boolean) => {
      const user = isFirstUser ? 'firstUser' : 'secondUser';

      return (
        currentValues && {
          name: screeningsWithDoctors[currentStatistics[user]].name,
          data: currentValues[currentStatistics[user]].data,
        }
      );
    },
    [currentStatistics, currentValues, screeningsWithDoctors],
  );

  const [calendar, setCalendar] = useState<CalendarEvent[]>([]);
  const [selectedDate, setDate] = useState<AppDate>(Dates.getToday());
  const [isDateClicked, setDateClicked] = useState(false);

  const handleDecreaseMonth = () => {
    setDate(selectedDate.month(selectedDate.month() - 1));
  };

  const handleIncreaseMonth = () => {
    setDate(selectedDate.month(selectedDate.month() + 1));
  };

  const handleToday = () => {
    setDate(Dates.getToday());
  };

  const calendarItem = (
    <TreatmentCalendar
      calendar={calendar}
      date={selectedDate}
      setDate={setDate}
      onDecrease={handleDecreaseMonth}
      onIncrease={handleIncreaseMonth}
      onToday={handleToday}
      setDateClicked={setDateClicked}
    />
  );

  const currentEvent = calendar.find((event) => Dates.getDate(event.date).isSame(selectedDate, 'date'));

  const panelItem = <TreatmentPanel event={currentEvent} />;

  
  return (
    <S.ScheduleCard
      title={<ScheduleHeader currentStatistics={currentStatistics} setCurrentStatistics={setCurrentStatistics} />}
      padding={0}
    >
      <RowStyled gutter={[10, 10]} wrap={false}>
      <>
        <BaseCol md={12}>{calendarItem}</BaseCol>
        <BaseCol md={12}>{panelItem}</BaseCol>
      </>
      </RowStyled>
      <ScheduleChart firstUser={getUserStatistic(true)} secondUser={getUserStatistic(false)} />
    </S.ScheduleCard>
  );
};

const RowStyled = styled(BaseRow)`
  min-height: 21.75rem;
`;
