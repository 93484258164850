import styled from 'styled-components';
import { Card } from 'antd';

export const GraphContainer = styled.div`
  font-family: Noto Sans KR sans-serif;

  .title {
    color: #212529;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    padding: 1rem;
  }

  .target-card-lists {
    display: flex;
    gap: 16px;
  }
`;

export const ResultCardWrapper = styled(Card)`
  background: var(--neutral-100, #f8f9fa);
  border-radius: 16px;
  margin-top: 24px;
  .graph {
    max-height: 700px;
    width: 100%;
    > canvas {
      //width: 1195px !important;
      //height: 650px !important;
    }
  }
  .tab-lists {
    display: flex;
    gap: 16px;
  }

  .graph-area {
    margin-top: 50px;

    .title {
      color: #212529;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }
  }

  @media (max-width: 1800px) {
    .graph {
      max-height: 600px;
      width: 100%;
      > canvas {
        width: 1300px !important;
        height: 600px !important;
      }
    }
  }
`;
