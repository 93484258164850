import React from 'react';
import { ButtonProps as AntButtonProps, Button as AntdButton } from 'antd';
import { ButtonType, Severity } from '@app/interfaces/interfaces';
import * as S from './BaseButton.styles';

export const { Group: ButtonGroup } = AntdButton;

export interface BaseButtonProps extends AntButtonProps {
  severity?: Severity;
  buttontype?: ButtonType;
  noStyle?: boolean;
  round?: boolean;
}

export const BaseButton = React.forwardRef<HTMLElement, BaseButtonProps>(
  ({ className, round, severity, buttontype, noStyle, children, ...props }, ref) => (
    <S.Button ref={ref} className={className} $round={round} $noStyle={noStyle} {...props} $severity={severity} $buttontype={buttontype}>
      {children}
    </S.Button>
  ),
);
