import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
  .ant-modal-header {
    border-bottom: none !important;

    /* mbKR/Headline M */
    font-family: Noto Sans KR !important;
    font-size: 17px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 24px; /* 141.176% */
  }
  .ant-modal-title {
    color: var(--primary-500, #556aeb) !important;
  }
  .ant-modal-body {
    padding-top: 8px;
  }
  .ant-modal-footer {
    border-top: none !important;
  }
  p {
    font-weight: 350 !important;
    font-family: Noto Sans KR;
    //font-size: 11px;
  }
`;

export const Desc = styled.p`
  margin-top: 16px;
`;
