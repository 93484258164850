import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { useReactiveVar } from '@apollo/client';
import { userDatas } from '@app/hocs/cache';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  const userData = useReactiveVar(userDatas);
  const profileUrl = userData?.category === 'parent' ? '/profile/personal-info/parent' : '/profile/personal-info';
  return (
    <div {...props}>
      <S.Text>
        <Link to={profileUrl}>{t('profile.title')}</Link>
      </S.Text>
      <S.ItemsDivider />
      <S.Text>
        <Link to="/logout">{t('header.logout')}</Link>
      </S.Text>
    </div>
  );
};
