import * as S from './index.styles';
interface Props {
  isActive: boolean;
  name: string;
  bMonth: number;
  sex: string;
  onChangeTargetInfo: (id: string) => void;
  targetId: string;
}

export default function BaseTargetInfoCard({ isActive, name, bMonth, sex, targetId, onChangeTargetInfo }: Props) {
  return (
    <S.CardWrapper
      isActive={isActive}
      onClick={() => {
        onChangeTargetInfo(targetId);
      }}
    >
      <span className="name">{name}</span>
      {bMonth / 12 > 3 ? `${(bMonth / 12) | 0}세 ${bMonth % 12}개월` : `${bMonth} 개월`} ・ {sex}
    </S.CardWrapper>
  );
}
