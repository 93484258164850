import styled from 'styled-components';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { Card } from 'antd';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

export const OverviewContainer = styled.div`
  font-family: 'NotoSansKR', sans-serif;
  .ant-card-body {
    padding: 24px 64px !important;
  }
  .title {
    color: #212529;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    padding: 1rem;
  }
`;

export const CardWrapper = styled(BaseCard)<{ slideWidth: string }>`
  margin-top: 35px;
  border-radius: 16px;

  // antd carousel custom
  .top-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .add-target-btn {
      > img {
        position: relative;
        top: -2px;
      }
      display: inline-flex;
      border: none;
      gap: 10px;
      align-items: center;
      padding: 10px 24px 10px 16px;
      border-radius: 100px;
      background: var(--primary-400, #6e82fe);
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      &:hover {
        background-color: #1d2f99 !important;
      }
      cursor: pointer;
    }
  }
  .ant-carousel {
    width: 100% !important;
  }
  //
  .ant-carousel .slick-list .slick-slide > div > div {
    //width: 97% !important;
    width: ${({ slideWidth }) => slideWidth} !important;
    display: flex !important;
  }
  //.slick-slide > div {
  //  display: inline-block;
  //  //width: 97%; /* 각 카드의 너비를 50%로 설정합니다. */
  //  padding: 0 5px; /* 왼쪽 및 오른쪽 여백을 추가합니다. */
  //  box-sizing: border-box; /* 패딩을 너비에 포함시킵니다. */
  //}

  //.slick-current {
  //  width: 97% !important;
  //}
  //
  ///* 마지막 슬라이드의 오른쪽 간격을 없애기 위해 마지막 슬라이드에 대한 스타일 추가 */
  //.slick-slide:last-child {
  //  margin-right: 0;
  //}

  .target-count {
    color: var(--neutral-700, #495057);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .target-lists {
    display: flex;
    margin-top: 20px;

    gap: 50px;
  }
`;

export const TargetCard = styled.div<{ isActive: boolean }>`
  padding: 32px 40px;
  border-radius: 16px;
  //border: 1px solid var(--primary-500, #556aeb);
  border: ${({ isActive }) => (isActive ? '#556aeb' : 'none')};
  background: ${({ isActive }) => (isActive ? '#ebefff' : '#F8F9FA')};
  flex: 1;
  display: flex;
  cursor: pointer !important;
  gap: 25px;

  .target-img {
    flex: 1;
    .profile-img {
      object-fit: cover;
      border-radius: 50%;
      width: 120px;
      height: 120px;
    }
    .profile {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: #d9d9d9;
      position: relative;
      > .empty {
        object-fit: cover;
        align-items: center;
        justify-content: center;
        position: absolute;
        display: inline-block;
        transform: translate(20%, 32%);
      }
    }
  }

  .target-info {
    flex: 4;
    display: flex;
    flex-direction: column;
    .detail {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .edit {
        cursor: pointer;
      }

      .info-wrapper {
        display: flex;
        align-items: center;
      }
      .name {
        color: #212529;
        font-size: 36px;
        font-weight: 500;
        margin-right: 16px;
      }
      color: #adb5bd;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .result-area {
    color: #495057;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    align-items: center;
    margin-top: 22px;
    display: flex;
    gap: 16px;
  }
`;

export const TargetResultTag = styled.div<{ background: string }>`
  border-radius: 30px;
  border: 1px solid var(--neutral-400, #ced4da);
  background: var(--White, #fff);
  padding: 8px 32px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  .status {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    //background: #c50000;
    background-color: ${({ background }) => background};
  }
`;

export const TargetResultCardWrapper = styled(BaseRow)`
  border-radius: 16px;
  border: 1px solid var(--primary-500, #556aeb);
  background: var(--primary-100, #ebefff);
  min-height: 700px;
  padding: 24px;
  margin-top: 32px;
`;

export const ResultLists = styled(BaseCol)`
  .top-area {
    display: flex;
    justify-content: space-between;
    color: #adb5bd;
    font-size: 14px;
    font-weight: 350;
    align-items: center;
  }

  .title {
    color: #495057;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .more {
    display: inline-flex;
    align-items: center;

    cursor: pointer;
    > img {
      transform: translateY(-10%);
    }
  }

  .result-lists {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 580px;
    overflow-y: auto;
  }
`;

export const TargetResultCard = styled.div<{ background?: string }>`
  padding: 16px 32px;
  border-radius: 16px;
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-area {
    display: flex;
    gap: 32px;
  }

  .result-text {
    color: var(--neutral-700, #495057);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 30px;
  }

  .status {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -2px;

    //background: #c50000;
    background-color: ${({ background }) => background};
  }

  .date {
    color: #6c757d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .right-area {
    color: #adb5bd;
    font-style: normal;
    font-weight: 350;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    > img {
      transform: translateY(-9%);
    }
  }
`;

export const GraphArea = styled(BaseCol)`
  .graph-labels {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    .label-status {
      display: inline-flex;
      gap: 8px;
      align-items: center;
    }
  }

  .ant-card-body {
    padding: 24px 48px !important;
  }
  .graph-area {
    max-height: 400px !important;
    width: 100%;
  }
  .normal-status {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #10c34c;
    border-radius: 50%;
  }

  .gray-status {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #e9ecef;
    border-radius: 50%;
  }

  .warning-status {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #fce64c;
    border-radius: 50%;
  }

  .danger-status {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #c50000;
    border-radius: 50%;
  }

  .title {
    color: #495057;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .graph-info {
    max-height: 580px;

    .header {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      .title {
        color: #212529;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
`;
