import { useTranslation } from 'react-i18next';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import './video.css';
import { Dates } from '@app/constants/Dates';
import { useMutation, useReactiveVar } from '@apollo/client';
import { ymaDatas, ymDatas } from '@app/hocs/cache';
import { calculateMonthsDifference, getFormatedAge, getExamMonthFromReserved, getMonth } from '@app/constants/YMData';
import { Progress, Typography } from 'antd';
import { Upload, UploadOptions } from 'tus-js-client';
import { createYMTaskGQL, updateYMTaskGQL } from '@app/hocs/gql';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import styled from 'styled-components';
import alertImg from '@app/assets/icons/alert_icon.svg';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { processDownload } from '@app/utils/downloader';
import { findMissingTitles } from '@app/utils/utils';
import active_arrow from '@app/assets/icons/active_arrow.png';
import disabled_arrow from '@app/assets/icons/disabled_arrow.png';

import dayjs from 'dayjs';
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const LinkBaseButton = styled(BaseButton)<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? '#6E82FE' : '#E9ECEF')} !important;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
  border: none;
  color: ${({ isActive }) => (isActive ? '#FFFFFF' : '#ADB5BD')} !important;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    background: ${({ isActive }) => (isActive ? '#1D2F99' : '#E9ECEF')} !important;
  }
`;

//export const VIDEO_URL = 'https://customer-9e9lqt027etamtgx.cloudflarestream.com';
export const VIDEO_URL = 'https://v.mbisq.com/';// 'https://mbisqinc.tw4.quickconnect.to';
//export const VIDEO_URL = 'https://mbisqinc.quickconnect.to';
// https://mbisqinc.quickconnect.to/240407/강남구/대웅리틀베어/김이서(210609)/강남구_대웅리틀베어_김이서(210609)_영상.mp4
export const VideoForm = (props: any) => {
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const cWidth = 711;
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [selectVTitle, setSelectVTitle] = useState('영상1');
  const [vRatio, setVRatio] = useState(16 / 9); // r = w/h = 16/9
  const [vWidth, setVWidth] = useState(0);
  const [vHeight, setVHeight] = useState(0);
  const previewRef = useRef<HTMLVideoElement>(null); // preview video
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [comment, setComment] = useState('');

  const ymData = useReactiveVar(ymDatas);
  const [dateFormatted, setDateFormated] = useState('');
  const bmonth = calculateMonthsDifference(dayjs(ymData.tg.data.bdate).valueOf(), parseInt(ymData.tg?.reservedAt));

  const [isLoading, setIsLoading] = useState(false);

  const [videoUrl, setVideoUrl] = useState('');

  const vData = useRef<any[]>([]);

  const videourl = useRef('');
  const thumburl = useRef('');
  const tkData = useRef<any>({});
  const typedComment = useRef('');
  const [videoWidth, setVideoWidth] = useState(cWidth);
  const [process, setProcess] = useState(1); // 0:video, 1:no video, 2:uploading, 3: uploaded, 4:video size error
  const [progress, setProgress] = useState(0);
  const [vIdx, setVIdx] = useState(0);
  const [searchParams] = useSearchParams();
  const ymaData = useReactiveVar(ymaDatas);
  // search 쿼리 값을 가져옵니다.
  const searchValue = searchParams.get('search');
  const searchTypeValue = searchParams.get('searchType');

  const updateTime = useRef(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      setVIdx(0);
      setLoading(true);
    }
  }, []);
  useEffect(() => {
    if (ymData.modal === 3 && ymData.update !== updateTime.current) {
      setProcess(1);
      updateTime.current = ymData.update;
      vData.current = [];
      console.log('******************* videoForm useEffect:' + ymData.update + ':' + ymData.modal);

      // if (ymData.tk.length === 0) createTask(ymData.tg.data.gid, ymData.tg.data.id);
      // else {
      setDateFormated(Dates.format(parseInt(ymData.tg.reservedAt), 'YYYY년 M월 D일 - H시 m분'));

      const idx = ymData.tk.map((task: any, idx: any) => {
        // if (ymData.tk[idx]?.title?.includes('학부모 동영상 채점')) return;
        // if (ymData.tk[idx]?.title?.includes('센터 동영상 채점')) return;
        // if (ymData.tk[idx]?.category?.includes('assess')) return;

        vData.current[idx] = ymData.tk[idx].data ? JSON.parse(ymData.tk[idx].data) : {};
        vData.current[idx].title = ymData.tk[idx].title;
        vData.current[idx].id = ymData.tk[idx].id;
        vData.current[idx].category = ymData.tk[idx].category;
        // vData.current[idx].videofile = ymData.tk[idx]?.videofile;
        if (vData.current[idx].video !== '') return idx;
      });
      if (idx[0] !== undefined) {
        selectTask(idx[0]);
      }
      if (ymData?.tg?.group?.data.name.includes('학부모검사') && bmonth) {
        const missingTasks = findMissingTitles(vData.current, bmonth);
        const vDataLength = vData.current.length;
        missingTasks.forEach((item, idx) => {
          vData.current[vDataLength + idx] = {};
          vData.current[vDataLength + idx].title = item.title;
          vData.current[vDataLength + idx].category = item.category;
          vData.current[vDataLength + idx].video = '';
        });
      }
      // }
    }
  }, [ymData.update]);

  const onFinish = async (values = {}) => {
    videourl.current = '';
    setComment('');
    setVRatio(0);
    setVWidth(0);

    setTimeout(() => {
      setFieldsChanged(true);
      //notificationController.success({ message: t('common.success') });
      props.onOk();
    }, 100);
  };

  const selectExamTask = (idx: number) => {
    setVIdx(idx);
    if (examVData[idx] === undefined) {
      videourl.current = '';
      return;
    }
    videourl.current = examVData[idx].video;

    if (!videourl.current) {
      setProcess(1);
    } else {
      // setProcess(process === 0 ? 3 : 0);
      setProcess(0);
    }

    setComment(examVData[idx].comment);
    setVideoArea(examVData[idx].vr);
  };

  const selectTask = (idx: number) => {
    setVIdx(idx);
    console.log('selectTask(' + process + '):' + idx, vData.current[idx]?.video, vData.current[idx]?.videofile);
    // const videoUrl = vData.current[idx].video;
    let videoUrl = '';

    if (vData.current[idx]?.videofile) {
      // const videoId = vData.current[idx]?.videofile.split('/').pop().split('.')[0];
      //videoUrl = `${VIDEO_URL}/videos/${vData.current[idx]?.videofile}`;
      videoUrl = `${VIDEO_URL}${vData.current[idx]?.videofile}`;
    } else {
      videoUrl = vData.current[idx].video;
    }

    //cors videoUrl = vData.current[idx].video;

    if (!videoUrl) {
      videourl.current = '';
      return;
    }
    console.log('selectTask(' + process + '):' + idx);
    // videourl.current = vData.current[idx].video;
    videourl.current = videoUrl;
    // videourl.current = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4';

    if (!videourl.current) {
      setProcess(1);
    } else {
      // setProcess(process === 0 ? 3 : 0);
      setProcess(0);
    }

    setComment(vData.current[idx].comment);
    setVideoArea(vData.current[idx].vr);
  };

  const refreshVideo = (idx: number) => {
    setProcess(process === 3 ? 0 : 3);

    if (ymData.tg?.group?.data?.name?.includes('어린이집') && selectVTitle.includes('영상')) {
      const findIdx = vData.current.findIndex((data) => data.title === selectVTitle);
      if (vData.current[findIdx] === undefined) return;

      videourl.current = vData.current[findIdx].video;
      // setProcess(process === 3 ? 0 : 3);
      setComment(vData.current[findIdx].comment);
      setVideoArea(vData.current[findIdx].vr);
      return;
    }

    if (vData.current[vIdx] === undefined) return;

    videourl.current = vData.current[vIdx].video;
    // setProcess(process === 3 ? 0 : 3);
    setComment(vData.current[vIdx].comment);
    setVideoArea(vData.current[vIdx].vr);
  };

  const setVideoArea = (vr: number) => {
    console.log('setVideoArea(' + process + ') - vr:' + vr);
    setVRatio(vr);
    if (vr === 0) vr = 1.4;
    if (vr > 1) {
      // horiz
      let vW = 711;
      if (vr < 1.4) vW = 711 * 0.7;
      const vH = vW / vr;

      setVWidth(vW - 1); //400/vRatio|0));
      setVHeight(vH > 400 ? 400 : vH);
      setOffsetX(0);
      // setOffsetX(cWidth - vW < 0 ? 0 : (cWidth - vW) / 2);
      // setOffsetY(400 - vH < 0 ? 0 : (400 - vH) / 2);
      setOffsetY(0);
    } else if (vr === 0) {
      // default 1.4
      setVHeight(0);
      setVWidth(0);
      setOffsetX(0);
      setOffsetY(0);
    } else {
      // vert
      const vW = (400 * vr) | 0;
      setVHeight(400);
      setVWidth(vW);
      // setOffsetX(cWidth - vW < 0 ? 0 : (cWidth - vW) / 2);
      setOffsetX(0);

      setOffsetY(0);
    }
  };

  const [uploadVideo, setUploadVideo] = useState(false);
  const fileInput = useRef<any>(null);
  const videoFile = useRef<File | null>(null);
  const [fileSize, setFileSize] = useState(0);

  const selectFile = async (e: React.FormEvent<HTMLInputElement>) => {
    //previewRef.current.src
    if (fileInput.current.files.length !== 0) setUploadVideo(true);

    const size = ((fileInput.current.files[0].size as number) / (1024 * 1024)) | 0;
    setFileSize(size);

    // const inputElement = e.target as HTMLInputElement;
    // if (inputElement.files && inputElement.files.length > 0) {
    //   const videoFile = inputElement.files[0];
    //   const videoElement = document.createElement('video');
    //   videoElement.src = URL.createObjectURL(videoFile);
    //   videoElement.addEventListener('loadedmetadata', () => {
    //     const videoWidth = videoElement.videoWidth;
    //     const videoHeight = videoElement.videoHeight;
    //
    //     console.log(`비디오 너비: ${videoWidth}px`);
    //     console.log(`비디오 높이: ${videoHeight}px`);
    //
    //     URL.revokeObjectURL(videoElement.src);
    //     videoElement.remove();
    //     setVideoArea(videoWidth / videoHeight);
    //   });
    // }
    if (previewRef.current !== null && size < 100) {
      const video = document.getElementById('video-preview');

      if (video !== null) {
        video.onloadedmetadata = function (e: any) {
          console.log(
            e.target.videoWidth,
            e.target.videoHeight,
            e.target.videoWidth / e.target.videoHeight,
            'video Width',
          );

          setVideoArea(e.target.videoWidth / e.target.videoHeight);
        };
      }

      if (fileInput.current.files.length !== 0) {
        const reader = new FileReader();
        videoFile.current = fileInput.current.files[0];
        reader.readAsDataURL(videoFile.current as Blob);
        reader.addEventListener('load', function () {
          if (video !== null) {
            video.setAttribute('src', reader.result as string);
            //video.src = reader.result; // bloburl
            console.log(video);
          } else ymData.tk[vIdx].data.video !== '' ? setProcess(0) : setProcess(1);
        });
      }
    } else setProcess(4);
  };

  const fileUpload = async (e: Event) => {
    if (!uploadVideo) {
      return;
    }
    e.preventDefault();
    let file = null;
    console.log('[', fileInput.current.files.length, ']');
    if (fileInput.current.files.length !== 0) {
      file = fileInput.current.files[0];
      setProgress(0);

      startUpload(file, 5 * 1024 * 1024);
    }
  };

  const getExpiryDate = () => {
    const theDate = new Date();
    theDate.setHours(theDate.getHours() + 5);
    return theDate.toISOString();
  };

  const [createYMTask, createYMTaskRequest] = useMutation(createYMTaskGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.createYMTask.success === true) {
        //setMessage('');
      } else {
        notificationController.error({ message: data.createYMTask.error });
      }
    },
  });

  const createAssessmentTask = (tkId: string, taskDataIds?: string[], skip?: boolean, type?: boolean) => {
    const tg = ymData.tg;
    const taskIDs = [];
    const exMonth = getExamMonthFromReserved(tg.data.bdate, tg.data.epweek, tg.data.epday,Number( tg.reservedAt));
    const bmonth = getMonth(tg.data.bdate);
    // const category = ymData.tk[vIdx].category === 'e_0' ? 'assess_1' : 'assess_2';
    // const title = ymData.tk[vIdx].title || '영상';
    const category = type ? 'assess_2' : 'assess_1';
    const title = selectVTitle;
    const owner = tg?.executers?.length ? tg?.executers[0]?.id : ymaData?.eqid;

    const taskIdDatas = ymData?.tk?.map((item: any) => item.id);
    const taskIds = taskDataIds && taskDataIds.length ? taskDataIds : taskIdDatas?.length ? taskIdDatas : [tkId];

    const data = JSON.stringify({
      video: videourl.current,
      thumb: thumburl.current,
      comment: typedComment.current,
      vr: vRatio,
      vwidth: videoWidth,
      bmonth: bmonth,
      exmonth: exMonth,
    });

    createYMTask({
      variables: {
        input: {
          owner: owner,
          title: title,
          category: category,

          data: data,

          executeAt: new Date(),
          tasks: taskIds,

          group: tg.group.id,
          target: tg.id,
          // tasks:
        },
      },
    }).then(async (result) => {
      const {
        data: {
          createYMTask: { success, data, id },
        },
      } = result;
      if (vData.current.length && skip) {
        const vIdxLength = vData.current.length;
        vData.current[vIdxLength] = {};
        vData.current[vIdxLength] = JSON.parse(data);

        vData.current[vIdxLength].video = videourl.current;
        vData.current[vIdxLength].comment = typedComment.current;
        vData.current[vIdxLength].vr = vRatio;
        vData.current[vIdxLength].category = category;
        vData.current[vIdxLength].id = id;
        vData.current[vIdxLength].title = selectVTitle;
      }
      // if (type) {
      ymDatas({
        ...ymData,
        tk: [...ymData.tk, { id: id, data: data, status: 2, title: title, category: category }],
        tkid: id,
      });
      // }
    });
  };
  const updateVData = (index: number, data: string, id: string, title?: string, category?: any): void => {
    const parsedData = JSON.parse(data);
    vData.current[index] = {
      ...parsedData,
      video: videourl.current,
      comment: typedComment.current,
      vr: vRatio,
      category: 'e_0',
      id,
      title: title ?? selectVTitle,
    };
  };
  const createOnlineTask = (
    title: string,
    category: string,
    isUpdate?: boolean,
    assessTaskId?: string,
    examTasksId?: string[],
  ) => {
    const tg = ymData.tg;
    const exMonth = getExamMonthFromReserved(tg.data.bdate, tg.data.epweek, tg.data.epday, Number(tg.reservedAt));
    const bmonth = getMonth(tg.data.bdate);

    tkData.current.video = videourl.current;
    tkData.current.thumb = thumburl.current;
    tkData.current.comment = typedComment.current;
    tkData.current.vr = vRatio;
    tkData.current.vwidth = videoWidth;

    try {
      createYMTask({
        variables: {
          input: {
            owner: ymData.eqid || ymaData?.eqid,
            title,
            category,
            status: 2,
            data: JSON.stringify({
              ...tkData.current,
              name: '',
              bmonth: bmonth,
              exmonth: exMonth,
            }),

            executeAt: new Date(),
            group: tg.group.id,
            target: tg.id,
          },
        },
      })
        .then(async (result) => {
          uploadWorkingModify.current = 0;
          const {
            data: {
              createYMTask: { success, data, id },
            },
          } = result;
          await updateYMTask({
            variables: {
              id: id,
              input: {
                status: 2,
              },
            },
          }).then((resp) => {
            // if (!vData.current[vIdx]?.video) {
            if (isUpdate) {
              const findIdx = vData.current.findIndex((item: any) => item.title === title);
              updateVData(findIdx, data, id, title, category);
              // vData.current[findIdx] = JSON.parse(data);
              // vData.current[findIdx].video = videourl.current;
              // vData.current[findIdx].comment = typedComment.current;
              // vData.current[findIdx].vr = vRatio;
              // vData.current[findIdx].category = category;
              // vData.current[findIdx].id = id;
              // vData.current[findIdx].title = title;
            } else {
              updateVData(vIdx, data, id, title, category);

              // vData.current[vIdx] = JSON.parse(data);
              // vData.current[vIdx].video = videourl.current;
              // vData.current[vIdx].comment = typedComment.current;
              // vData.current[vIdx].vr = vRatio;
              // vData.current[vIdx].category = category;
              // vData.current[vIdx].id = id;
              // vData.current[vIdx].title = title;
            }

            if (isUpdate && assessTaskId && examTasksId) {
              const examTasks = [...examTasksId, id];
              updateAssessTask(examTasks, assessTaskId);
            } else {
              createAssessmentTask(id, [], false, true);
            }

            // 최초 생성 시
            // }
          });
          ymDatas({
            ...ymData,
            tk: [...ymData.tk, { id: id, data: data, status: 2, title: title, category: category }],
            tkid: id,
          });

          // selectTask(vIdx);
          if (!videourl.current) {
            setProcess(1);
          } else {
            setProcess(process === 0 ? 3 : 0);
          }

          setComment(vData.current[vIdx].comment);
          setVideoArea(vData.current[vIdx].vr);
        })
        .catch((err) => {
          //console.log('login error',createUserError)
          //d console.log(err)
          console.log('catch', err);
        });
    } catch (err) {
      console.log('catchErr');
    }
  };

  const createVideoTask = (assessTask?: number, examId?: string, updateId?: string) => {
    const tg = ymData.tg;
    const exMonth = getExamMonthFromReserved(tg.data.bdate, tg.data.epweek, tg.data.epday, Number(tg.reservedAt));
    const bmonth = getMonth(tg.data.bdate);

    tkData.current.video = videourl.current;
    tkData.current.thumb = thumburl.current;
    tkData.current.comment = typedComment.current;
    tkData.current.vr = vRatio;
    tkData.current.vwidth = videoWidth;

    const title = selectVTitle;

    try {
      createYMTask({
        variables: {
          input: {
            owner: ymData.eqid || ymaData?.eqid,
            title,
            category: 'e_0',
            status: 2,
            data: JSON.stringify({
              ...tkData.current,
              name: '',
              bmonth: bmonth,
              exmonth: exMonth,
            }),

            executeAt: new Date(),
            group: tg.group.id,
            target: tg.id,
          },
        },
      })
        .then(async (result) => {
          const {
            data: {
              createYMTask: { success, data, id },
            },
          } = result;
          await updateYMTask({
            variables: {
              id: id,
              input: {
                status: 2,
              },
            },
          }).then((resp) => {
            // if (!vData.current[vIdx]?.video) {

            if (assessTask === 1 && updateId) {
              updateAssessTask([id, examId], updateId);
              const vLength = vData.current.length;
              updateVData(vLength, data, id);

              // vData.current[vLength] = {};
              // vData.current[vLength] = JSON.parse(data);
              //
              // vData.current[vLength].video = videourl.current;
              // vData.current[vLength].comment = typedComment.current;
              // vData.current[vLength].vr = vRatio;
              // vData.current[vLength].category = 'e_0';
              // vData.current[vLength].id = id;
              // vData.current[vLength].title = selectVTitle;
              return;
            }

            if (assessTask === 2 && examId) {
              createAssessmentTask(id, [id, examId]);
              return;
            }

            if (vData.current[vIdx] === undefined) {
              updateVData(vIdx, data, id);

              // vData.current[vIdx] = {};
              // vData.current[vIdx] = JSON.parse(data);
              //
              // vData.current[vIdx].video = videourl.current;
              // vData.current[vIdx].comment = typedComment.current;
              // vData.current[vIdx].vr = vRatio;
              // vData.current[vIdx].category = 'e_0';
              // vData.current[vIdx].id = id;
              // vData.current[vIdx].title = selectVTitle;
            }

            createAssessmentTask(id, [], true);
            // }
          });
          ymDatas({
            ...ymData,
            tk: [...ymData.tk, { id: id, data: data, status: 2, title: selectVTitle, category: 'e_0' }],
            tkid: id,
          });

          // selectTask(vIdx);
          // if (!videourl.current) {
          //   setProcess(1);
          // } else {
          //   setProcess(process === 0 ? 3 : 0);
          // }
          setProcess(!videourl.current ? 1 : process === 0 ? 3 : 0);

          setComment(vData.current[vIdx].comment);
          setVideoArea(vData.current[vIdx].vr);
        })
        .catch((err) => {
          //console.log('login error',createUserError)
          //d console.log(err)
          console.log('catch', err);
        });
    } catch (err) {
      console.log('catchErr');
    }
  };
  const [updateYMTask, updateYMTaskRequest] = useMutation(updateYMTaskGQL, {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
      if (data.updateYMTask.success !== true) {
        notificationController.error({ message: data.updateYMTask.error });
      }
    },
  });
  const updateAssessTask = (tkId: string[], id: string) => {
    const tg = ymData.tg;
    const taskIDs = [];
    const exMonth = getExamMonthFromReserved(tg.data.bdate, tg.data.epweek, tg.data.epday, Number(tg.reservedAt));
    const bmonth = getMonth(tg.data.bdate);
    const category = 'assess_1';
    const title = selectVTitle;
    const owner = tg?.executers?.length ? tg?.executers[0]?.id : '';
    const taskIds = [...tkId];

    const data = JSON.stringify({
      video: videourl.current,
      thumb: thumburl.current,
      comment: typedComment.current,
      vr: vRatio,
      vwidth: videoWidth,
      bmonth: bmonth,
      exmonth: exMonth,
    });

    updateYMTask({
      variables: {
        id: id,
        input: {
          data,
          // status: 2,
          executedAt: new Date(),
          tasks: taskIds,
        },
      },
    }).then((result) => {
      const {
        data: {
          updateYMTask: { success, data, id },
        },
      } = result;

      vData.current[vIdx].video = videourl.current;
      vData.current[vIdx].comment = typedComment.current;
      vData.current[vIdx].vr = vRatio;
    });
  };
  const updateAssessmentTask = (id: string) => {
    tkData.current.video = videourl.current;
    tkData.current.thumb = thumburl.current;
    tkData.current.comment = typedComment.current;
    tkData.current.vr = vRatio;
    tkData.current.vwidth = videoWidth;

    updateYMTask({
      variables: {
        id: id,
        input: {
          data: JSON.stringify(tkData.current),
          // status: 2,
          // executedAt: new Date(),
        },
      },
    })
      .then((result) => {
        const {
          data: {
            updateYMTask: { success, data, id },
          },
        } = result;

        vData.current[vIdx].video = videourl.current;
        vData.current[vIdx].comment = typedComment.current;
        vData.current[vIdx].vr = vRatio;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTask = (skip?: boolean) => {
    tkData.current.video = videourl.current;
    tkData.current.thumb = thumburl.current;
    tkData.current.comment = typedComment.current;
    tkData.current.vr = vRatio;
    tkData.current.vwidth = videoWidth;

    updateYMTask({
      variables: {
        id: ymData.tk[vIdx].id,
        input: {
          data: JSON.stringify(tkData.current),
          status: 2,
          executedAt: new Date(),
        },
      },
    })
      .then((result) => {
        const {
          data: {
            updateYMTask: { success, data, id },
          },
        } = result;

        if (skip) {
          const findIdx = vData.current.findIndex((data: any) => {
            return data.title === selectVTitle;
          });
          vData.current[findIdx].video = videourl.current;
          vData.current[findIdx].comment = typedComment.current;
          vData.current[findIdx].vr = vRatio;
        }

        vData.current[vIdx].video = videourl.current;
        vData.current[vIdx].comment = typedComment.current;
        vData.current[vIdx].vr = vRatio;

        uploadWorkingModify.current = 0;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadWorkingModify = useRef(0);

  const updateAndSearch = async () => {
    uploadWorkingModify.current = 0;
    setProcess(3);
    setBRefresh(true);
    setTimeout(
      () => {
        if (!searchValue) {
          props.onUpdate();
        } else {
          props.searchExam(searchValue, searchTypeValue);
        }
      },
      ymData.tg?.group?.data?.name?.includes('어린이집') ? 700 : 1500,
    );
  };

  const startUpload = (file: any, chunkSize: number) => {
    if (uploadWorkingModify.current === file.lastModified) return;

    uploadWorkingModify.current = file.lastModified;

    const tusUploadOptions = {
      endpoint: 'https://cloudflare-streams-tus-upload.blueton.workers.dev/',
      chunkSize: chunkSize,
      metadata: {
        expiry: getExpiryDate(),
        //maxDurationSeconds: 3600,
        name: 'admin_' + ymData.tg.data.name + '_' + vIdx + '.mp4', //file.name,
        //"Upload-Metadata": "requiresignedurls,maxdurationseconds MzYwMA==",
      },

      onError(error: Error) {},
      async onSuccess() {
        if (ymData.tg?.group?.data?.name?.includes('학부모검사')) {
          const taskData = ymData?.tk;
          const assessTask = ymData?.tk.find((item: any) => item.category.includes('assess'));
          if (!taskData.length && !vData.current[vIdx].video) {
            // task data 아무것도 없고 현재 선택된 task에 비디오 없을때 task생성, video Task 생성
            createOnlineTask(vData.current[vIdx].title, vData.current[vIdx].category);
            setUploadVideo(false);
            await updateAndSearch();
          } else if (assessTask?.id && !vData.current[vIdx].video) {
            const filteredExamTasks = ymData.tk
              .filter((item: any) => item.category.includes('e_'))
              .map((item: any) => item.id);
            createOnlineTask(
              vData.current[vIdx].title,
              vData.current[vIdx].category,
              true,
              assessTask.id,
              filteredExamTasks,
            );
            await updateAndSearch();

            // setProcess(3);
            // setBRefresh(true);
            // setTimeout(() => {
            //   if (!searchValue) {
            //     props.onUpdate();
            //   } else {
            //     props.searchExam(searchValue, searchTypeValue);
            //   }
            // }, 1500);
          } else {
            updateTask();
            await updateAndSearch();

            // setProcess(3);
            // setBRefresh(true);
            // setTimeout(() => {
            //   if (!searchValue) {
            //     props.onUpdate();
            //   } else {
            //     props.searchExam(searchValue, searchTypeValue);
            //   }
            // }, 1500);
          }

          return;
        }

        if (ymData.tg?.group?.data?.name?.includes('어린이집')) {
          if (examVData.length && examVData[vIdx] && examVData[vIdx]?.video) {
            updateTask(true);
            setUploadVideo(false);
            await updateAndSearch();

            // setProcess(3);
            // setBRefresh(true);
            // setTimeout(() => {
            //   if (!searchValue) {
            //     props.onUpdate();
            //   } else {
            //     props.searchExam(searchValue, searchTypeValue);
            //   }
            // }, 700);

            return;
          }

          if (examVData.length === 0) {
            await createVideoTask();
            // ymData.update = ymData.update + 1;
          } else if (examVData.length === 1) {
            const filteredData = vData.current.filter((data: any) => data.category === 'assess_1');

            if (filteredData[0]?.id) {
              await createVideoTask(1, examVData[0]?.id, filteredData[0].id);
              setUploadVideo(false);
              await updateAndSearch();

              // setProcess(3);
              // setBRefresh(true);
              // setTimeout(() => {
              //   if (!searchValue) {
              //     props.onUpdate();
              //   } else {
              //     props.searchExam(searchValue, searchTypeValue);
              //   }
              // }, 700);

              return;
            }
            if (!filteredData.length && examVData.length) {
              await createVideoTask(2, examVData[0]?.id);
              setUploadVideo(false);
              await updateAndSearch();

              // setProcess(3);
              // setBRefresh(true);
              // setTimeout(() => {
              //   if (!searchValue) {
              //     props.onUpdate();
              //   } else {
              //     props.searchExam(searchValue, searchTypeValue);
              //   }
              // }, 700);
              return;
            }
          }
        } else {
          if (!ymData?.tk?.length) {
            await createVideoTask();
          } else {
            updateTask();
          }

          if (!vData.current[vIdx]?.video) {
          } else {
            // ymData.tk.filter((data) => {
            //
            // })
            const filteredAssessmentTask = ymData.tk.filter((item: any) => item.id === vData.current[vIdx].id);

            if (filteredAssessmentTask.length) {
              updateAssessmentTask(filteredAssessmentTask[0]?.id);
            }
          }
        }

        setUploadVideo(false);
        await updateAndSearch();

        // setProcess(3);
        // setBRefresh(true);
        // setTimeout(() => {
        //   if (!searchValue) {
        //     props.onUpdate();
        //   } else {
        //     props.searchExam(searchValue, searchTypeValue);
        //   }
        // }, 1500);
      },
      onAfterResponse(req: any, res: any) {
        return new Promise((resolve) => {
          const mediaIdHeader = res.getHeader('stream-media-id');
          if (mediaIdHeader ) {
            const url = req.getURL();
            const vid = mediaIdHeader;

            if( vid !== null ){
              videourl.current = 'https://customer-9e9lqt027etamtgx.cloudflarestream.com/' + vid + '/watch';
              thumburl.current = 'https://customer-9e9lqt027etamtgx.cloudflarestream.com/' + vid + '/thumbnails/thumbnail.jpg';

            }
          }
          resolve();
        });
      },
      onProgress(bytesUploaded: number, bytesTotal: number) {
        //const progress = (bytesUploaded / file.size) * 100;
        const percentage = ((bytesUploaded / bytesTotal) * 100) | 0; //.toFixed(2); Math.floor(progress)
        setProgress(percentage);
      },
    } as UploadOptions;

    const tusUpload = new Upload(file, tusUploadOptions);
    tusUpload.start();
  };

  const onLoad = (e: any) => {
    console.log('onLoad::' + e.toString());
    loadScript();
  };

  const videoScript = useRef<any>(null);
  const loadScript = () => {
    videoScript.current = document.createElement('script');

    videoScript.current.src = 'https://embed.cloudflarestream.com/embed/sdk.latest.js';
    //script.defer = true;
    videoScript.current.type = 'text/javascript';
    videoScript.current.async = false;

    videoScript.current.onload = () => {
      // console.log('onload');
      // selectTask(0);
      // @ts-ignore
      const player = Stream(document.getElementById('stream-player'));

      console.log(player, 'player', 'onLoad');

      player.addEventListener('play', (e: any) => {
        console.log('playing!' + e.toString());
      });
      player.addEventListener('canplay', (e: any) => {
        console.log('can play!' + e.toString());
        setProcess(0);
        setBRefresh(false);
        try {
          //if (document.body.contains(videoScript.current))
          document.body.removeChild(videoScript.current);
        } catch {}
      });
      player.addEventListener('loadedmetadata', (e: any) => {
        if (e.target === null) return;
        console.log('loadedmetadata!' + e.target.videoWidth + '/' + e.target.videoHeight);
      });
      player.play().catch(() => {
        console.log('playback failed, muting to try again');
        player.muted = true;
        //player.play();
      });
      player.addEventListener('error', (e: any) => {
        console.log('error!' + e.toString());
        selectTask(vIdx);
      });
      //player.play();
    };
    document.body.appendChild(videoScript.current);
  };

  const [bRefresh, setBRefresh] = useState(false);

  const getIframeStream = () => {
    //videourl.current = "https://customer-9e9lqt027etamtgx.cloudflarestream.com/a402958984286f71851d2e8a0e508c17/iframe";
    if (!videourl.current) return;

    const vurl = videourl.current.replace('watch', 'iframe') + '?update=' + Date.now() + '&preload=auto'; //+'&autoplay=true';

    console.log('getIframeStream : (' + offsetX + ',' + offsetY + ',' + vWidth + ',' + vHeight + ')url:' + vurl);

    return (
      // <iframe
      //   src={vurl}
      //   id="stream-player"
      //   ref={iframeRef}
      //   onLoad={(e) => onLoad(e)}
      //   style={{
      //     border: 'none',
      //     position: 'absolute',
      //     zIndex: 100,
      //     maxHeight: '400px',
      //     maxWidth: '711px',
      //     top: offsetY,
      //     left: offsetX,
      //     height: vHeight === 0 ? '80%' : vHeight + 'px',
      //     width: vWidth === 0 ? '80%' : vWidth + 'px',
      //     borderRadius: '10px',
      //     overflow: 'visible',
      //   }}
      //   allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
      //   allowFullScreen={true}
      // ></iframe>
      <iframe
        src={vurl}
        id="stream-player"
        ref={iframeRef}
        onLoad={(e) => onLoad(e)}
        style={{
          border: 'none',
          position: 'absolute',
          zIndex: 100,
          maxHeight: '400px',
          maxWidth: '711px',
          top: offsetY,
          left: offsetX,
          height: '400px',
          width: '710px',
          borderRadius: '10px',
          overflow: 'visible',
        }}
        allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
        allowFullScreen={true}
      ></iframe>
    );
    //  autoplay;
  };

  // const getPreviewVideo = () => {
  //   console.log('getPreviewVideo : (' + offsetX + ',' + offsetY + ',' + vWidth + ',' + vHeight + ')');
  //   return (
  //     <video
  //       ref={previewRef}
  //       id="video-preview"
  //       preload="metadata"
  //       style={{
  //         position: 'absolute',
  //         overflow: 'clip',
  //         top: offsetY,
  //         left: offsetX,
  //         maxHeight: '400px',
  //         maxWidth: '711px',
  //         //left: "0px", //width: (cWidth)+"px", height: (400)+"px",
  //         borderRadius: '10px',
  //         objectFit: 'cover',
  //         zIndex: 20,
  //       }}
  //       width={vWidth === 0 ? '80%' : vWidth + 'px'}
  //       height={vHeight === 0 ? '80%' : vHeight + 'px'}
  //       max-height={'400px'}
  //       autoPlay={false}
  //       controls={true}
  //       playsInline
  //       onLoadedMetadata={(e: BaseSyntheticEvent) => {
  //         // cWidth
  //         const vw = e.target.videoWidth,
  //           vh = e.target.videoHeight;
  //         const vr = vw / vh; // 1.4
  //         console.log(vw + ',' + vh + ',' + vr);
  //         setVideoArea(vr);
  //       }}
  //     ></video>
  //   );
  // };

  const getVideoMessage = (message: any) => {
    return (
      <div
        style={{
          position: 'absolute',
          top: '0%',
          left: 0,
          height: '400px',
          width: '711px',
          backgroundColor: '#fffa',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderRadius: '10px',
          border: process === 3 ? '0px solid #6E82FE' : '1px solid #6E82FE',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 111,
          textAlign: 'center',
        }}
      >
        {process === 1 && <img src={alertImg} />}
        <Typography
          style={{
            padding: 5,
            marginTop: 1,
            marginLeft: 1,
            color: '#495057',
            fontSize: 12,
            fontWeight: 400,
            whiteSpace: 'pre-line',
          }}
        >
          {message}
        </Typography>
      </div>
    );
  };

  console.log(videourl.current, '---- video', process, 'process', bRefresh, vIdx, vData.current, getIframeStream());
  if (process === 0 || (process === 3 && getIframeStream())) {
    console.log('test 2');
  }

  const renderedVideoButton = (data: any) => {
    if (data?.length === 0) {
      return (
        <>
          <VideoButton
            round
            isnotselected={vIdx !== 0}
            isvideoexist={false}
            // type="default"
            onClick={() => {
              if (!uploadVideo) {
                setProcess(1);
                setSelectVTitle('영상1');
                setProgress(0);

                selectExamTask(0);
              }
            }}
            style={{ marginBottom: '15px', backgroundColor: '#556AEB', color: 'white' }}
          >
            영상1
          </VideoButton>
          <VideoButton
            round
            isnotselected={vIdx !== 1}
            isvideoexist={false}
            // type="default"
            onClick={() => {
              if (!uploadVideo) {
                setProcess(1);
                setSelectVTitle('영상2');
                setProgress(0);

                selectExamTask(1);
              }
            }}
            style={{ marginBottom: '15px', backgroundColor: '#556AEB', color: 'white' }}
          >
            영상2
          </VideoButton>
        </>
      );
    } else if (data?.length === 1) {
      return (
        <>
          <VideoButton
            round
            isnotselected={vIdx !== 0}
            isvideoexist={true}
            // type="default"
            onClick={() => {
              if (!uploadVideo) {
                setSelectVTitle(data[0]?.title);
                setProgress(0);

                selectExamTask(0);
              }
            }}
            style={{ marginBottom: '15px', backgroundColor: '#556AEB', color: 'white' }}
          >
            {data[0]?.title}
          </VideoButton>
          <VideoButton
            round
            isnotselected={vIdx !== 1}
            isvideoexist={false}
            // type="default"
            onClick={() => {
              if (!uploadVideo) {
                setProcess(1);
                setSelectVTitle('영상2');
                setProgress(0);

                selectExamTask(1);
              }
            }}
            style={{ marginBottom: '15px', backgroundColor: '#556AEB', color: 'white' }}
          >
            영상2
          </VideoButton>
        </>
      );
    } else if (data?.length === 2) {
      return (
        <>
          <VideoButton
            round
            isnotselected={vIdx !== 0}
            isvideoexist={true}
            // type="default"
            onClick={() => {
              if (!uploadVideo) {
                setSelectVTitle('영상1');
                setProgress(0);

                selectExamTask(0);
              }
            }}
            style={{ marginBottom: '15px', backgroundColor: '#556AEB', color: 'white' }}
          >
            {data[0]?.title}
          </VideoButton>
          <VideoButton
            round
            isnotselected={vIdx !== 1}
            isvideoexist={true}
            // type="default"
            onClick={() => {
              if (!uploadVideo) {
                setSelectVTitle('영상2');
                setProgress(0);

                selectExamTask(1);
              }
            }}
            style={{ marginBottom: '15px', backgroundColor: '#556AEB', color: 'white' }}
          >
            {data[1]?.title}
          </VideoButton>
        </>
      );
    }
  };

  const examVData = vData.current
    .map((item: any, idx: number) => {
      if (
        !item?.title?.includes('학부모 동영상 채점') &&
        !item?.title?.includes('센터 동영상 채점') &&
        !item?.category?.includes('assess')
      ) {
        return item;
      }
    })
    .filter((data) => data !== undefined);
  const arrayWithInspectionName = examVData.filter((data) => data?.title?.includes('검사'));

  console.log(bmonth, 'bMonth', ymData?.tk, vData.current, 'examData: ', examVData);

  const isExistAssessData = () => {
    const filteredData = ymData?.tk?.filter((item: any) => item.category.includes('assess_') ); // && item?.status !== 5
    console.log(filteredData);
    return !!filteredData?.length;
  };
  const navigate = useNavigate();
  // check this 
  const exMonth = getExamMonthFromReserved( ymData.tg.data.bdate, ymData.tg.data.epweek, ymData.tg.data.epday, Number( ymData.tg.reservedAt));
  return (
    <BaseButtonsForm
      {...formItemLayout}
      layout="inline"
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      initialValues={{ 'input-number': 3, 'checkbox-group': ['A', 'B'], rate: 3.5 }}
      footer={
        <BaseButtonsForm.Item style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '10px' }}>
          <BaseSpace align="end">
            <BaseButton
              // type="primary"
              style={{ background: '#E9ECEF', border: 'none', color: '#495057', fontWeight: 400 }}
              onClick={() => {
                // if (iframeRef?.current) {
                //   iframeRef.current.src = '';
                // }
                props.onCancel();
                setProgress(0);
                setSelectVTitle('영상1');
                setVIdx(0);

                // setProcess(0);
              }}
            >
              닫기
            </BaseButton>

            <LinkBaseButton
              isActive={isExistAssessData()}
              type="primary"
              onClick={() => {
                if (!isExistAssessData()) {
                  return;
                }
                const assessTask = ymData.tk.filter((item: any) => {
                  return item.category.includes('assess_') && item.status !== 5;
                });
                const taskData = JSON.parse(assessTask[0].data);

                navigate(
                  `/assessment-dashboard/assessment/target/${ymData.tg.id}/${assessTask[0]?.id}?exmonth=${taskData?.exmonth}`,
                );
              }}
            >
              채점 바로가기 <img src={isExistAssessData() ? active_arrow : disabled_arrow} />
            </LinkBaseButton>
          </BaseSpace>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >
      <BaseRow gutter={[10, 10]}>
        <BaseCol span={12}>
          <BaseButton
            round
            type="default"
            onClick={() => {
              return;
            }}
          >
            {dateFormatted}
          </BaseButton>
        </BaseCol>

        <BaseCol span={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <BaseButton
            round
            type="text"
            onClick={() => {
              return;
            }}
          >
            {ymData.tg.data.name} | 검사 개월 
            {getFormatedAge(exMonth,1)}
          | {ymData.tg.data.uid}
          </BaseButton>
        </BaseCol>

        <BaseCol span={18}>
          <div style={{ height: '400px', width: '711px', backgroundColor: '#ffffff' }}>
            {(process === 0 || process === 3) && getIframeStream()}
            {process === 1 && getVideoMessage('해당 태스크의 영상이 없습니다. \n' + '영상을 업로드해 주세요.')}
            {/*{process === 2 && getPreviewVideo()}*/}
            {process === 3 &&
              getVideoMessage(
                bRefresh
                  ? '아직 영상이 인코딩 중입니다. 이 작업은 시간이 소요될 수 있습니다. 나중에 다시 확인해 주세요.'
                  : '해당 과제의 영상을 서버에서 불러오는 중입니다.',
              )}
            {process === 4 &&
              getVideoMessage(
//                '해당 과제의 영상이 너무 큽니다. 바로 업로드 해주세요. 미리보기는 영상 업로드 완료후 확인 가능합니다.(' +
                '업로드 해주세요. 미리보기는 영상 업로드 완료후 확인 가능합니다.(' +
                  fileSize +
                  'MB)',
              )}
            {bRefresh && (
              <BaseButton
                round
                type="default"
                onClick={() => {
                  refreshVideo(0);
                }}
                style={{
                  top: '20px',
                  left: '20px',
                  marginBottom: '15px',
                  width: '120px',
                  backgroundColor: '#556AEB',
                  color: 'white',
                  zIndex: 120,
                }}
              >
                Refresh
              </BaseButton>
            )}
          </div>
          <BaseInput.TextArea
            value={comment}
            disabled={true}
            placeholder="검사시 입력된 코멘트가 없습니다."
            style={{ marginTop: '35px', background: '#fff' }}
          ></BaseInput.TextArea>
        </BaseCol>

        <BaseCol span={6}>
          <BaseSpace
            wrap={true}
            align="center"
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <BaseCol span={24}>
              {ymData.tg?.group?.data?.name?.includes('어린이집') && !arrayWithInspectionName.length
                ? renderedVideoButton(examVData)
                : vData.current.map((task: any, idx: any) => {
                    if( task.exmonth === undefined || task.exmonth === null) return;
                    const type=' ('+Math.abs(task?.exmonth)+')';
                    //if (task?.exmonth !== Math.abs(exMonth)) type=' ('+Math.abs(task?.exmonth)+')';
                    // if (task.category.includes('assess')) return;
                    // if (!task?.category) return;

                    const videoUrl = task?.videofile || task.video;

                    if (task?.title?.includes('학부모 동영상 채점')) return;
                    if (task?.title?.includes('센터 동영상 채점')) return;

                    if (task?.category?.includes('assess')) return;

                    return (
                      <VideoButton
                        round
                        key={idx}
                        isnotselected={vIdx !== idx}
                        isvideoexist={!!videoUrl}
                        // type="default"
                        onClick={() => {
                          if (!videoUrl) {
                            setProcess(1);
                          }
                          selectTask(idx);
                        }}
                        style={{ marginBottom: '15px', backgroundColor: '#556AEB', color: 'white' }}
                      >
                        {task.title === '' ? '영상' : task.title} {type}
                      </VideoButton>
                    );
                  })}
              {}
            </BaseCol>
            {(process === 2 || process === 4) && uploadVideo && (
              <BaseCol span={24} style={{ width: 170 }}>
                <Progress percent={progress} size="small" width={100} />
              </BaseCol>
            )}
            <BaseRow style={{ position: 'absolute', bottom: '80px', left: '30px' }}>
              <BaseCol span={24}>
                <BaseSpace
                  wrap={false}
                  align="center"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    // position: 'absolute',
                    // bottom: '80px',
                    // marginLeft: '20px',
                  }}
                >
                  <BaseButton
                    type="primary"
                    onClick={() => {
                      fileInput.current.click();
                      setVideoArea(0);
                      setProcess(0);
                      setProcess(2);
                    }}
                  >
                    파일 선택
                  </BaseButton>
                  <input
                    ref={fileInput}
                    id="file"
                    type="file"
                    accept="video/*"
                    style={{ display: 'none' }}
                    onChange={(e: any) => selectFile(e)}
                  />
                  <BaseButton type="primary" onClick={(e: any) => fileUpload(e)}>
                    업로드
                  </BaseButton>

                  {videourl.current && (
                    <BaseButton
                      type="primary"
                      onClick={async () => {
                        console.log(vIdx, 'vIdx', vData.current[vIdx]?.video);
                        // https://customer-9e9lqt027etamtgx.cloudflarestream.com/d6b6c9dc9a48d600bf65afb22c0f0548/manifest/video.m3u8
                        const manifestUrl = videourl.current.replace('/watch', '/manifest/video.m3u8');
                        setIsLoading(true);
                        await processDownload(manifestUrl);
                        setIsLoading(false);
                      }}
                    >
                      {isLoading ? '다운로드 중...' : 'PC에 저장'}
                    </BaseButton>
                  )}
                </BaseSpace>
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </BaseCol>
        {/*<BaseCol span={24}>{comment}</BaseCol>*/}
      </BaseRow>
    </BaseButtonsForm>
  );
};

export const VideoButton = styled(BaseButton)<{
  isnotselected: boolean;
  isvideoexist: boolean;
}>`
  background: ${({ isnotselected, isvideoexist }) =>
    !isnotselected ? '#556AEB' : !isvideoexist ? 'var(--neutral-200, #E9ECEF)' : '#EBEFFF'} !important;
  color: ${({ isnotselected, isvideoexist }) =>
    !isnotselected ? '#fff' : !isvideoexist ? '#6C757D' : '#212529'} !important;
  border: ${({ isnotselected, isvideoexist }) =>
    !isnotselected ? 'none' : !isvideoexist ? '1px solid var(--neutral-500, #ADB5BD)' : '#8FA0FF'} !important;

  cursor: pointer !important;
`;


/**
 * 이선
{"video":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/68fd9ed72c69c72efc03e67998b6864c/watch","thumb":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/68fd9ed72c69c72efc03e67998b6864c/thumbnails/thumbnail.jpg","comment":"","vr":0,"vwidth":711,"name":"","bmonth":27,"exmonth":-27,"videofile": "68fd9ed72c69c72efc03e67998b6864c.mp4"}
김하윤
{"video":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/3df533fbfa34a103192a3afc4157f5e7/watch","thumb":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/3df533fbfa34a103192a3afc4157f5e7/thumbnails/thumbnail.jpg","comment":"","vr":1.7777777777777777,"vwidth":345,"bmonth":25,"exmonth":25,"category":"e_0","name":"검사 1","videofile": "3df533fbfa34a103192a3afc4157f5e7.mp4"}
신민재
{"exmonth":23,"category":"e_0","name":"검사 1","video":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/078cae7059bd5a065e911ae16b7d2525/watch","thumb":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/078cae7059bd5a065e911ae16b7d2525/thumbnails/thumbnail.jpg","comment":"","bmonth":23,"vr":1.7777777777777777,"vwidth":319,"videofile": "078cae7059bd5a065e911ae16b7d2525.mp4"}
유인나
{"exmonth":19,"category":"e_0","name":"검사 1","video":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/8fc55bf86e28e47534ee5c8e2321a678/watch","thumb":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/8fc55bf86e28e47534ee5c8e2321a678/thumbnails/thumbnail.jpg","comment":"","bmonth":19,"vr":1.7777777777777777,"vwidth":343,"videofile": "8fc55bf86e28e47534ee5c8e2321a678.mp4"}
최은우
{"exmonth":19,"category":"e_0","name":"검사 1","video":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/22b486e432bb6ce583a1b15122b2b9c5/watch","thumb":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/22b486e432bb6ce583a1b15122b2b9c5/thumbnails/thumbnail.jpg","comment":"","bmonth":20,"vr":1.7777777777777777,"vwidth":318,"videofile": "22b486e432bb6ce583a1b15122b2b9c5.mp4"}
이민아
{"exmonth":20,"category":"e_0","name":"검사 1","video":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/a57b40f8e8ec4166a6625b1433a8500e/watch","thumb":"https://customer-9e9lqt027etamtgx.cloudflarestream.com/a57b40f8e8ec4166a6625b1433a8500e/thumbnails/thumbnail.jpg","comment":"","bmonth":20,"vr":1.7777777777777777,"vwidth":336,"videofile": "a57b40f8e8ec4166a6625b1433a8500e.mp4"}

*/
/*
{message}
        {vRatio +'/'+ vWidth +'/'+ vHeight +'/'+ (400/vRatio|0) +'/'+ (400*16/9|0)}
      <BaseButton type="default" onClick={()=>{return;}} style={{marginBottom:'15px', width: '120px', backgroundColor: '#EBEFFF'}}>블록 쌓기</BaseButton>
        <Stream controls responsive={true} src={videourl.current} height="500px"/>
      <BaseCol span={24} >
      <ReactPlayer className='react-player' width={'99%'} height='100%' controls={true} playing
        url={videourl.current}
        light={<img src={thumburl.current} alt='Thumbnail' />}
        />
      </BaseCol>

          <div style={{ position: 'absolute', top: '0%', height: '400px', width: '711px', backgroundColor:'white', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderRadius: '10px', border:'1px solid #6E82FE', display:'flex', flexDirection: 'column',alignItems: 'center',justifyContent:'center', zIndex:10}}>
            <Typography style={{padding:5, marginTop:1, marginLeft:1, color:'#495057', fontSize: 12, fontWeight: 400, whiteSpace: 'pre-line'}}>file size: {fileSize}MB</Typography></div>}

            <div style={{ position: 'absolute', zIndex:101, top: '0%', height: '400px', width: '711px', backgroundColor:'#fffa', backgroundPosition: 'center',
      backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderRadius: '10px', border:'1px solid #6E82FE',
      display:'flex', flexDirection: 'column',alignItems: 'center',justifyContent:'center'}}>
        <Typography style={{padding:5, marginTop:1, marginLeft:1, color:'#495057', fontSize: 12, fontWeight: 400, whiteSpace: 'pre-line'}}>해당 과제의 영상이 업로드중입니다.</Typography>
        </div>}

          onError={(e:SyntheticEvent) => { setMessage(e.toString()); }}
          onCanPlay={(e:SyntheticEvent) => { setMessage(e.target.toString());}}
      <video ref={previewRef} id="video-preview"
                preload="metadata"
                style={{ position: 'absolute', top: '0%',
                //left: "0px", //width: (cWidth)+"px", height: (400)+"px",
                borderRadius: '10px', objectFit: "cover", zIndex:20}}
                width={vWidth}
                autoPlay={false} controls={true}
                playsInline
                onLoadedMetadata= {(e:SyntheticEvent) => {
                  // cWidth
                  // @ts-ignore | Weird
                  const vw = e.target.videoWidth, vh =e. target.videoHeight;
                  const vr = vw/vh; // 1.4
                  setVRatio(vr);

                  if( vr > 1 ){ // horiz r = w/h
                    setVWidth(cWidth-30);
                    setVHeight((cWidth-30)/vr);
                  }
                  else{ // vert
                    // vr 0.75 : 1.6
                    // vr 0.56 : 1.9
                    let wCoeff = 0.55;
                    if( vr < 0.65 )
                      wCoeff = 0.45;

                    setVWidth((cWidth-30)*wCoeff);
                    setVHeight((cWidth-30)*wCoeff/vr);
                  }

                  setVideoWidth(vw);
                  console.log(vw+','+vh+','+vr);

                }}
                ></video>

      <div style={{ height: "450px", width: "200px"}}>
      <Stream controls src={video} className="videoStream"/>
      </div>
  // https://developers.cloudflare.com/stream/uploading-videos/direct-creator-uploads/
  // https://capacitorjs.com/docs/web/pwa-elements
  // https://github.com/JesusFilm/core

  const startUploadDirect = (file:any, size:number) => {

      const tusUploadOptions = {
        endpoint: "https://api.cloudflare.com/client/v4/accounts/3ede8243614dc67aeb7b4473d31a2825/stream?direct_user=true",
        headers: {
          Authorization: 'Bearer FE3ZmuZrzDDYA2czAm9YRxfZZ90kJMxcbzn82C08',
        },
        chunkSize: 50 * 1024 * 1024,
        retryDelays: [0, 3000, 5000, 10000, 20000], // Indicates to tus-js-client the delays after which it will retry if the upload fails
        metadata: {
          filename: file.name,
          filetype: "video/mp4",
          expiry: getExpiryDate(),
          //maxDurationSeconds: 3600,
          name: 'video.mp4', //file.name,
          //"Upload-Metadata": "requiresignedurls,maxdurationseconds MzYwMA==",
        },
        uploadSize: size,

      onError(error:Error) {},
      onSuccess() {
        // https://customer-9e9lqt027etamtgx.cloudflarestream.com/90e44de81c5746f57098abe31a979d3e/watch
        updateTask();
        setUploadVideo(false);
      },
      onAfterResponse(req:any, res:any) {
        const url = req.getURL()
        const vid = res.getHeader('stream-media-id');
        videourl.current = "https://customer-9e9lqt027etamtgx.cloudflarestream.com/"+vid+"/watch";
        thumburl.current = "https://customer-9e9lqt027etamtgx.cloudflarestream.com/"+vid+"/thumbnails/thumbnail.jpg";

      },
      onProgress(bytesUploaded:number, bytesTotal: number) {
        //const progress = (bytesUploaded / file.size) * 100;
        const percentage = ((bytesUploaded / bytesTotal) * 100)|0; //.toFixed(2); Math.floor(progress)
        setProgress(percentage);
      },
    } as UploadOptions;

    const upload = new Upload(file, tusUploadOptions);
    upload.start();
  }

      */
/*
    window.addEventListener( "loadedmetadata", (e:any) => {
      setVideoAttribute(e.target.videoWidth, e.target.videoHeight);
    }, true);
    */
/*
    const script = document.createElement('script');

    script.src = "https://embed.cloudflarestream.com/embed/sdk.latest.js";
    script.async = true;

    document.body.appendChild(script);
*/

/*
    if( vw === 0 ) vw = 711;
    if( vh === 0 ) vh = 400;
    //const vw = previewRef.current !== null ? previewRef.current.videoWidth:0, vh =previewRef.current !== null ? previewRef.current.videoHeight:0;
    setVRatio(vr);

    if( vr > 1 ){ // horiz r = w/h
      setVWidth(cWidth-30);
      setVHeight((cWidth-30)/vr);
    }
    else{ // vert
      // vr 0.75 : 1.6
      // vr 0.56 : 1.9
      let wCoeff = 0.55;
      if( vr < 0.65 )
        wCoeff = 0.45;

      setVWidth((cWidth-30)*wCoeff);
      setVHeight((cWidth-30)*wCoeff/vr);
    }

    //setVideoWidth(vw);
    setMessage(vw+','+vh+','+vr);
    */
/*
    if( vr < 100 ) setVideoWidth(400);
    else if( vr < 140 ) setVideoWidth(170);
    else if( vr < 180) setVideoWidth(140);
    else setVideoWidth(100);
    */

/*
   const playerRef = useRef(null);
   const handlePlayerReady = (player:any) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

   // https://videojs.com/guides/react/
   const getVideoJS = () => {
    const videoJsOptions = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      loop: true,
      sources: [{
        src: 'https://customer-m033z5x00ks6nunl.cloudflarestream.com/b236bde30eb07b9d01318940e5fc3eda/manifest/video.m3u8',//'https://customer-9e9lqt027etamtgx.cloudflarestream.com/a402958984286f71851d2e8a0e508c17/watch',
        //type: 'video/w'//'application/x-mpegURL' //'video/mp4'
      }]
    };

    return (
      <>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </>
    );
   }
// style={{ position: 'absolute', top: '0%', width: '711px', height: '400px', borderRadius: '10px', overflow:'clip', div:{ div: { width: '100%', height: '100%', overflow:"visible"}}, aspectRatio: `${vRatio}`, marginTop:1} as React.CSSProperties}
  const videoRef = useRef();

   const getStream = () => { // React.CSSProperties // https://github.com/ant-design/ant-design/blob/master/components/tag/CheckableTag.tsx#L33
    return null;
   }
*/
/*
    return <div className='player'><Stream src={'https://customer-9e9lqt027etamtgx.cloudflarestream.com/a402958984286f71851d2e8a0e508c17/watch'}
    loop={true}
    controls={false}
    streamRef={videoRef}
    onLoadedMetaData={(e:any)=>{console.log("onLoadedMetaData:"+e)}}
    // @ts-ignore
    onProgress={() => console.log(videoRef.current?.currentTime)}
    autoplay={true}
    muted={true} /></div>
    */
/*
  useEffect(()=>{
    selectTask(selectedIdx);
  } ,[selectedIdx]);

  useEffect(()=>{
    if( process === 0){
      const stream = document.getElementById('stream-player');
      if( stream !== null){
        const player = Stream(stream as HTMLIFrameElement) as any;
        if( player !== null ){
          player.addEventListener('play', () => {
            console.l        <BaseCol span={24}>
g('playing!');
          });
          player.play().catch(() => {
            console.log('playback failed, muting to try again');
            player.muted = true;
            player.play();
          });
        }
      }
    }
  },[process]);
*/
/*
    if( iframeRef.current !== null ){
      //@ts-ignore
      const player = window.Stream(iframeRef.current) as any;
      if(player !== null){
        player.addEventListener('play', () => {
          console.log('playing!');
        });
        player.addEventListener('error', () => {
          console.log('error!');
        });

      }

    }
    */

/*
    const vp = document.getElementById('stream-player');
    if( vp !== null ){
    // @ts-ignore
    vp.src = videourl.current;
    // @ts-ignore
    const player = Stream(vp);
    //player.play();
    vp.onplay = function() {
      console.log('playing!');
    }
    vp.onloadedmetadata = function(e:any) {
      console.log(e);
    }
      player.addEventListener('play', () => {
        console.log('playing!');
      });
      player.play().catch(() => {
        console.log('playback failed, muting to try again');
        player.muted = true;
        player.play();
      });
    }
*/
/*
      file = fileInput.current.files[0];
      const f = fs.createReadStream(file.name);
      const size = fs.statSync(file.name).size;
      if( file !== null ) startUpload( f, size);

      const f = new File(
        //[blob], `video_${Date.now()}.webm`, {type: 'video/webm'}
        fileInput.current.files[0], {type: 'video/webm'}
        );
*/
/*
      const video = document.getElementById('video-preview');
      if( video !== null ){
        const srcUrl = video.getAttribute('src');
        if( srcUrl !== null ){
          const blob = await fetch(srcUrl).then(r => r.blob());
          if( file !== null ) startUpload( blob, 5 * 1024 * 1024,);
      //        if( file !== null ) startUpload( file, 5 * 1024 * 1024,);
        }

      }
      */
/*
        const si = setInterval(async() => {

          const result = await fetch(
            'https://api.cloudflare.com/client/v4/accounts/3ede8243614dc67aeb7b4473d31a2825/stream/'+videourl.current.split('/')[3],
            {
              headers: {
                'Authorization': 'Bearer FE3ZmuZrzDDYA2czAm9YRxfZZ90kJMxcbzn82C08',
                'Tus-Resumable': '1.0.0',
              },
              method: 'GET'
            },
          )
          //@ts-ignore
          console.log(result.readyToStream);

        }, 2000);
        */
