// @ts-nocheck

import * as S from './TargetResultGraph.style';
import * as G from '@app/pages/OverviewPage.styles';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useEffect, useRef, useState } from 'react';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseFormTitle } from '@app/components/common/forms/components/BaseFormTitle/BaseFormTitle';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { AllYMTags } from '@app/interfaces/interfaces';
import { getAssessmentByTargetIdGQL, getYMTags, getYMUserByIdGQL } from '@app/hocs/gql';
import { generateChartData, getChartData, getTargetResultData } from '@app/utils/utils';
import { targetPdfData, ymDatas } from '@app/hocs/cache';
import BaseTargetInfoCard from '@app/components/TargetResultLists/BaseTargetInfoCard';
import BaseTab from '@app/components/TargetResultLists/BaseTab';
import { Chart } from 'chart.js';
import { xAxisLabel } from '@app/constants/ui';
import { Loading } from '@app/components/common/Loading/Loading';
import { getMonth } from '@app/constants/YMData';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart: any, args: any, options: any) => {
    const { ctx } = chart;
    const data = chart.data.datasets[0].data;

    for (let i = 0; i < data.length; i++) {
      if (data[i] === null) {
        const xStart = chart.getDatasetMeta(0).data[i]?.x;
        const xEnd = chart.getDatasetMeta(0).data[i + 1]?.x;
        const yStart = chart.chartArea.top;
        const yEnd = chart.chartArea.bottom;
        ctx.fillStyle = options.color || '#E9ECEF'; // 배경색 설정
        ctx.fillRect(xStart, yStart, xEnd - xStart, yEnd - yStart);
      }
    }
  },
} as any;

const chartScales = {
  x: {
    grid: {
      display: true, // x축 그리드 라인 표시
    },
  },
  y: {
    min: 0,
    max: 4,
    // display: false,
    grid: {
      display: true, // x축 그리드 라인 표시
    },

    ticks: {
      display: false, // y축 값 표시하지 않음
      min: 0,
      max: 3,
      stepSize: 1,
      count: 4,
      // precision: 0,
    },
  },
} as any;
export const chartOptions: any = {
  events: ['click'],

  // 여기에 옵션을 설정합니다.
  scales: chartScales,
  plugins: {
    title: {
      display: false,
      text: '차트 제목',
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        pointStyle: 'circle', // 원 모양으로 레전드 아이콘 변경
        usePointStyle: true, // 원 모양 사용
      },
    },
    customCanvasBackgroundColor: {
      color: '#E9ECEF',
    },
  },
};

export default function TargetResultGraphPage() {
  const ymData = useReactiveVar(ymDatas);
  const navigate = useNavigate();
  const [targetLists, setTargetLists] = useState<any>([]);
  const [selectedTarget, setSelectedTarget] = useState<any>(null);
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  const [getYMUserById, { loading: ymUserLoading }] = useLazyQuery(getYMUserByIdGQL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [getAssessmentByTargetId, { loading: assessmentByTargetLoading }] = useLazyQuery(getAssessmentByTargetIdGQL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const { data: assessTags, loading: ymTagsLoading } = useQuery<AllYMTags>(getYMTags, {
    variables: { category: 'Assess' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const getChartOptions = (xLabels: any): any => {
    return {
      events: ['click'],
      // 여기에 옵션을 설정합니다.
      scales: chartScales,
      onClick: (event: any, elements: any) => {
        console.log('click', elements, event);
        if (elements && elements.length > 0) {
          const clickedPoint = elements[0];
          const datasetIndex = clickedPoint.datasetIndex;
          const dataIndex = clickedPoint.index;

          // 클릭한 데이터 포인트의 x 값 가져오기
          const xValue = xLabels[dataIndex];
          const extractedNumbers = xValue.match(/\d+/g);
          navigate(`/target-result-view?exmonth=${extractedNumbers}`);
          targetPdfData(selectedTarget.history);
        }
      },

      plugins: {
        title: {
          display: false,
          text: '차트 제목',
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            pointStyle: 'circle', // 원 모양으로 레전드 아이콘 변경
            usePointStyle: true, // 원 모양 사용
          },
        },
        customCanvasBackgroundColor: {
          color: '#E9ECEF',
        },
      },
    };
  };
  const fetchAssessmentData = async (targetsData: any, category: any) => {
    const data = [];
    for (const target of targetsData) {
      const result = await getAssessmentByTargetId({
        variables: {
          tgid: target.id,
          category: category.join(','),
        },
      });
      if (result.data.getYMAssessmentsByTargetId?.success) {
        const targetResult = getTargetResultData(
          result.data.getYMAssessmentsByTargetId?.tasks,
          assessTags?.getYMTags.tags,
        );

        data.push({ key: data.length + 1, ...targetResult });
      } else {
        data.push({
          key: data.length + 1,
          name: target.title,
          id: target.id,
          sex: target.data.sex === 1 ? '여' : '남',
          bdate: getMonth(target.data.bdate),
        });
      }
    }

    // console.log(data, 'data');
    setTargetLists(data);
  };

  const onChangeTargetInfo = (id: string) => {
    const findTarget = targetLists.find((target: any) => target.id === id);
    setSelectedTarget(findTarget);
  };

  const bootstrap = async () => {
    const category = assessTags?.getYMTags.tags.map((tag) => tag.code);

    const result = await getYMUserById({ variables: { ymuserid: ymData.eqid } });
    if (result.data?.getYMUserById?.success) {
      const targetsData = result.data?.getYMUserById?.ymuser?.targets.map((target: any) => {
        if (typeof target.data === 'string') {
          return { ...target, data: JSON.parse(target.data) };
        }

        return target;
      });

      fetchAssessmentData(targetsData, category);
    }
  };

  useEffect(() => {
    if (assessTags?.getYMTags.success) {
      bootstrap();
    }
  }, [assessTags]);

  useEffect(() => {
    if (!selectedTarget && targetLists.length) {
      setSelectedTarget(targetLists[0]);
    }
  }, [targetLists.length]);

  useEffect(() => {
    if (selectedTarget && chartRef?.current && selectedTarget.history) {
      // if (selectedTarget && chartRef?.current && chartRef?.current?.getContext() && selectedTarget.history) {
      const ctx = chartRef.current!.getContext('2d')!;

      const yDatas = generateChartData(selectedTarget.history, xAxisLabel);
      const updateYDatas = yDatas.map((item: any) => {
        if (item !== null) {
          return item + 1;
        }
        return item;
      });

      if (!yDatas) return;

      const chartDatasets = [
        {
          // label: '데이터셋 레이블',
          data: updateYDatas,
          radius: 5,
          pointBorderWidth: 0, // 포인트의 외곽 테두리 두께를 0으로 설정하여 테두리를 없앰
          borderColor: '#6C757D', // 데이터셋의 border 색상을 빨간색으로 설정

          pointBackgroundColor: updateYDatas.map((data: any) => {
            if (data === 1) {
              return '#C50000';
            } else if (data === 2) {
              return '#FCE64C';
            } else if (data === 3) {
              return '#10C34C';
            }
          }),

          pointStyle: 'circle', // 원 모양으로 데이터 포인트 스타일 변경
          pointBorderColor: 'blue', // 원의 테두리 색상 변경
          order: 1000, // 1000 또는 그 이상의 값을 사용
        },
      ] as any;

      console.log(xAxisLabel, updateYDatas, 'DATASD');
      const myChart = new Chart(ctx, {
        type: 'line', // 원하는 차트 유형 선택
        data: {
          labels: xAxisLabel,
          datasets: chartDatasets,
        },
        options: getChartOptions(xAxisLabel),

        plugins: [plugin],
      });
      console.log(chartRef);
      const canvas = myChart.canvas;

      console.log(canvas, 'canvas');

      // if (canvas) {
      //   canvas.addEventListener('mousemove', (event) => {
      //     if (myChart) {
      //       // console.log(myChart?.getElementsAtEventForMode(event, 'nearest'), 'myChart');
      //       const elements = myChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
      //     }
      //
      //     // chartRef.current.style.cursor = elements.length ? 'pointer' : 'default';
      //     console.log('mouseOver');
      //   });
      // }

      // setTimeout(() => {
      //   if (chartRef && chartRef?.current) {
      //     chartRef.current.addEventListener('mousemove', (event) => {
      //       if (myChart) {
      //         const elements = myChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
      //         chartRef.current.style.cursor = elements.length ? 'pointer' : 'default';
      //       }
      //     });
      //   }
      // }, 1000);

      return () => {
        // 컴포넌트가 언마운트될 때 차트를 파괴합니다.
        myChart.destroy();
      };
    }
  }, [selectedTarget]);

  if (ymUserLoading || ymTagsLoading || assessmentByTargetLoading) {
    return <Loading />;
  }

  return (
    <S.GraphContainer>
      <PageTitle>결과 그래프</PageTitle>

      <BaseRow justify="space-between" style={{ position: 'absolute', top: '0px' }}>
        <BaseCol xl={24} md={24}>
          <BaseFormTitle className="title">지난 결과 그래프</BaseFormTitle>
        </BaseCol>
      </BaseRow>

      <div style={{ maxWidth: '1700px' }}>
        <div className="target-card-lists" style={{ paddingBottom: '50px' }}>
          {targetLists.map((target: any) => (
            <BaseTargetInfoCard
              key={target?.id}
              name={target?.name}
              sex={target?.sex}
              bMonth={target.bdate}
              isActive={target.id === selectedTarget?.id}
              onChangeTargetInfo={onChangeTargetInfo}
              targetId={target.id}
            />
          ))}
        </div>

        {selectedTarget?.history?.length ? (
          <S.ResultCardWrapper>
            <div className="tab-lists">
              <BaseTab name="발달 검사" isActive={true} />
              <BaseTab name="CNT" isActive={false} />
              <BaseTab name="설문 조사" isActive={false} />
            </div>

            <div className="graph-area">
              <div className="title">심리 발달 선별 검사 결과</div>

              <div className="graph" style={{ margin: '0 auto' }}>
                <canvas ref={chartRef} />
              </div>
            </div>
            <G.GraphArea xl={12} sm={24} style={{ marginTop: '20px' }}>
              <div className="graph-labels" style={{ width: '1370px' }}>
                <div className="label-status">
                  <span className="gray-status" /> <span>검사 미완료</span>
                </div>
                <div className="label-status">
                  <span className="normal-status" /> <span>정상발달</span>
                </div>
                <div className="label-status">
                  <span className="warning-status" /> <span>관찰필요</span>
                </div>
                <div className="label-status">
                  <span className="danger-status" /> <span>도움필요</span>
                </div>
              </div>
            </G.GraphArea>
          </S.ResultCardWrapper>
        ) : null}
      </div>
    </S.GraphContainer>
  );
}
