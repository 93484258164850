import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
//import logo from 'assets/icons/logo.svg';
import logo from 'assets/images/center_ci.svg';
import logoDark from 'assets/logo-dark.png';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly } = useResponsive();

  const theme = useAppSelector((state) => state.theme.theme);

  const img = theme === 'dark' ? logo : logo;
  const userCategory = localStorage.getItem('userCategory');

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to={userCategory === 'parent' ? '/overview' : '/examlist-school-dashboard/0/0'}>
        <img src={img} alt="서울아이" width={165} height={165} style={{ overflow: 'auto'}}/>
      </S.SiderLogoLink>
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};

/*
<img src={img} alt="MBISQ" width={48} height={48} />
        <br/>*/
