import { UserModel } from '@app/domain/UserModel';
const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/template.png';

const testUser = {
  id: 1,
  firstName: '유진',
  lastName: '임',
  imgUrl: avatarImg,
  userName: '@eugene',
  email: {
    name: 'seouli@gmail.com',
    verified: true,
  },
  phone: {
    number: '01025031234',
    verified: false,
  },
  sex: 'male',
  birthday: '01/26/2087',
  lang: 'ko',
  country: 'KR',
  city: 'Seoul',
  address1: 'Hangbokhan',
  zipcode: 52116,
  website: 'mbisq.com',
  socials: {
    twitter: '@eugene',
    facebook: 'https://facebook.com/groups/eugene',
  },
};

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const readToken = (): string => {
  return localStorage.getItem('accessToken') || '';
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : testUser;
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
