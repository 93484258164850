import { useRef } from 'react';
import './video.css';
import { useParams } from 'react-router-dom';
import { VIDEO_URL } from './VideoForm';


export const VideoPopup = (props: any) => {
  const { type, keyword } = useParams();

  let vurl = '';
  if( type==='cf')
    vurl = 'https://customer-9e9lqt027etamtgx.cloudflarestream.com/'+keyword+'/iframe?update=1722654915165&preload=auto';//'https://v.mbisq.com/';// 'https://mbisqinc.tw4.quickconnect.to';
  else
    vurl = VIDEO_URL+keyword;//'https://v.mbisq.com/';// 'https://mbisqinc.tw4.quickconnect.to';
  const onLoad = (e: any) => {
    console.log('onLoad::' + e.toString());
    loadScript();
  };

  const videoScript = useRef<any>(null);
  const loadScript = () => {
    videoScript.current = document.createElement('script');

    videoScript.current.src = 'https://embed.cloudflarestream.com/embed/sdk.latest.js';
    //script.defer = true;
    videoScript.current.type = 'text/javascript';
    videoScript.current.async = false;

    videoScript.current.onload = () => {
      // console.log('onload');
      // selectTask(0);
      // @ts-ignore
      const player = Stream(document.getElementById('stream-player'));

      console.log(player, 'player', 'onLoad');

      player.addEventListener('play', (e: any) => {
        console.log('playing!' + e.toString());
      });
      player.addEventListener('canplay', (e: any) => {
        console.log('can play!' + e.toString());
        try {
          //if (document.body.contains(videoScript.current))
          document.body.removeChild(videoScript.current);
        } catch {}
      });
      player.addEventListener('loadedmetadata', (e: any) => {
        if (e.target === null) return;
        console.log('loadedmetadata!' + e.target.videoWidth + '/' + e.target.videoHeight);
      });
      player.play().catch(() => {
        console.log('playback failed, muting to try again');
        player.muted = true;
        //player.play();
      });
      player.addEventListener('error', (e: any) => {
        console.log('error!' + e.toString());

      });
      //player.play();
    };
    document.body.appendChild(videoScript.current);
  };

  return (
    <iframe
    src={vurl}
    id="stream-player"
    onLoad={(e) => onLoad(e)}
    style={{
      border: 'none',
      position: 'absolute',
      zIndex: 100,
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      borderRadius: '0px',
      overflow: 'visible',
    }}
    allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
    allowFullScreen={true}
  ></iframe>  );
};

