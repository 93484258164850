import React, { useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { userDatas } from '@app/hocs/cache';
import { makeReference, useReactiveVar } from '@apollo/client';

export const PARENT_PATH = [
  '/parent/accessallow',
  '/overview',

  '/target-result-lists',
  '/target-result-graph',
  '/target-result-view',
  '/profile/personal-info/parent',
];

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const userData = useReactiveVar(userDatas);
  const location = useLocation();

  const userCategory = localStorage.getItem('userCategory');
  //  useEffect(() => {
  const tokenKeeped = PARENT_PATH.includes(location.pathname)
    ? localStorage.getItem('parentUserToken')
    : localStorage.getItem('userToken');

  if (tokenKeeped !== null && userData.token === '') userDatas({ ...userData, token: tokenKeeped });
  //  },[]);

  // if (tokenKeeped && location.pathname === '/examinee-dashboard') {
  //   return <Navigate to={userCategory === 'parent' ? 'overview' : '/member/'} />;
  // }
  //
  // if ((tokenKeeped !== null || userData.token !== '') && userCategory === 'parent') {
  //   // userCategory가 'parent'이고 로그인 상태인 경우 PARENT_PATH에만 접근 허용
  //   if (PARENT_PATH.includes(location.pathname)) {
  //     return <>{children}</>;
  //   } else {
  //     return <Navigate to="/logout" replace />;
  //   }
  // } else if (tokenKeeped !== null || userData.token !== '') {
  //   return <>{children}</>;
  // } else {
  //   return <Navigate to="/auth/login" replace />;
  // }

  return tokenKeeped !== null || userData.token !== '' ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
