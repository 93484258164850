import { useEffect } from 'react';
import { NAVER_REDIRECT_URL } from '@app/constants/YMData';
import * as process from 'process';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createYMExecuterGQL,
  createYMUserGQL,
  getUserByName,
  getYMExecuterGQL,
  getYMUserGQL,
  logInWSocialM,
  signUpSocialM,
} from '@app/hocs/gql';
import { userDatas } from '@app/hocs/cache';
export interface UserData {
  getUserByName: {
    id: string;
    user: {
      username: string;
      id: string;
      name: string;
    };
    success: boolean;
    error?: string;
  };
}
export default function NaverCallbackPage() {
  let timerId: any;
  const navigate = useNavigate();
  const [getUserByEmail, { loading, data }] = useLazyQuery<UserData>(getUserByName);
  const [logInWithSocialMutation] = useMutation(logInWSocialM);

  const [signUpWithSocialMutation] = useMutation(signUpSocialM);
  const [getExecuterHandler, getExecuterRequest] = useLazyQuery(getYMExecuterGQL, {});
  const [getYMUserHandler] = useLazyQuery(getYMUserGQL, {});

  const [createExecuterHandler, createExecuterRequest] = useMutation(createYMExecuterGQL, {});
  const [createYMUserHandler] = useMutation(createYMUserGQL, {});
  const onLogin = async (email: string, id: string, accessToken: string, name: string) => {
    const loginResult = await logInWithSocialMutation({
      variables: { email, socialId: id, token: accessToken, type: 'naver' },
    });

    if (loginResult.data.logInWSocial.success) {
      userDatas({ ...userDatas(), uid: 'login', token: loginResult.data.logInWSocial.token, type: 'naver' });
      const userCategory = localStorage.getItem('userCategory');

      if (userCategory === 'parent') {
        localStorage.setItem('parentRecentLogin', 'naver');

        const getYMUser = await getYMUserHandler({
          variables: { uid: loginResult.data.logInWSocial.id },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        });

        if (getYMUser.data.getYMUser.success === false) {
          const createUser = await createYMUserHandler({
            variables: {
              input: {
                user: loginResult.data.logInWSocial.id,
                name: name,
                category: 'parent',
                data: JSON.stringify({}),
              },
            },
          });
        } else {
        }
      } else {
        localStorage.setItem('recentLogin', 'naver');
        const getUser = await getExecuterHandler({
          variables: { uid: loginResult.data.logInWSocial.id },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
          // pollInterval: 500
        });

        console.log(getUser.data.getYMExecuter, 'getUser');

        if (getUser.data.getYMExecuter.success === false) {
          const createExecuter = await createExecuterHandler({
            variables: {
              input: {
                user: loginResult.data.logInWSocial.id,
                name: name,
                category: 'exam',
                data: JSON.stringify({}),
              },
            },
          });
        } else {
        }
      }

      const isKeepLoginSocial = JSON.parse(localStorage.getItem('isKeepLoginBySocialLogin')!);
      if (isKeepLoginSocial) localStorage.setItem('userToken', loginResult.data.logInWSocial.token);

      localStorage.removeItem('isKeepLoginBySocialLogin');

      navigate('/accessallow');
    } else {
      alert('서버 에러가 발생했습니다. 로그인페이지로 이동합니다.');
      navigate('/auth/login');
    }
  };

  const handle = async () => {
    const { naver_id_login } = window as any;
    const _naver_id_login = new naver_id_login(process.env.REACT_APP_NAVER_CLIENT_ID, NAVER_REDIRECT_URL);
    let count = 0;
    async function naverSignInCallback() {
      count = count + 1;

      if (count === 100) {
        clearInterval(timerId);
        alert('로그인 정보를 불러오지 못했습니다. 로그인 페이지로 이동합니다.');
        navigate('/auth/login');
        return;
      }

      const email = _naver_id_login.getProfileData('email');
      const name = _naver_id_login.getProfileData('name');
      const id = _naver_id_login.getProfileData('id');
      const accessToken = _naver_id_login.oauthParams.access_token;
      if (email && name && id && accessToken) {
        console.log(timerId);
        clearInterval(timerId);
        count = 0;
        console.log(_naver_id_login.oauthParams.access_token);

        console.log(_naver_id_login.getProfileData('email'), 'herherherherh');
        console.log(_naver_id_login.getProfileData('name'), 'herherherherh', _naver_id_login.getProfileData('id'));

        try {
          const { data } = await getUserByEmail({
            variables: { username: email },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
          });
          if (!data?.getUserByName.success) {
            const signUpResult = await signUpWithSocialMutation({
              variables: { email, username: name, socialId: id, token: accessToken, type: 'naver' },
            });
            console.log(signUpResult.data, 'signup data');
            if (signUpResult.data.signUpWSocial.success) {
              console.log('회원가입 성공!');

              await onLogin(email, id, accessToken, name);
            }
          } else {
            await onLogin(email, id, accessToken, name);
          }
        } catch (err) {
          console.log(err);
          alert('서버 에러가 발생했습니다. 로그인 페이지로 이동합니다.');
          navigate('/auth/login');
        }
      }
    }
    // qkftuPCmSTzZ33U6M-cGF6jpDlGkd-7Q7SpGbFAPFts
    timerId = setInterval(() => {
      _naver_id_login.get_naver_userprofile(naverSignInCallback());
    }, 500);
  };
  useEffect(() => {
    handle();
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <>
      <h1>Loading....</h1>
    </>
  );
}
